import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";

import RestrictedContent from "./RestrictedContent";
import ChatRoom from "./ChatRoom";
import Spinner from "./Spinner";

import { useDeviceChatRoomId } from "../services/ChatService";
import { areDictsEqual } from "../utils/generalUtils";
import { useMobileLayout } from "../hooks/uiHooks";
import { isLoading } from "../utils/uiUtils";
import { ROOM_TYPES } from "../services/ChatService";
const useStyles = makeStyles((theme) => {
  return {
    root: (mobile) => ({
      height: "100%",
      width: "100%",
      paddingLeft: theme.spacing(mobile ? 1 : 2),
      paddingRight: theme.spacing(mobile ? 1 : 2),
    }),
  };
});

const DeviceMessagesContent = ({ deviceId, projectId, permissions }) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);
  const roomId = useDeviceChatRoomId(deviceId);

  if (isLoading(roomId)) return <Spinner />;

  return (
    <RestrictedContent permitted={permissions?.canReadDeviceExtra}>
      <div className={classes.root}>
        <ChatRoom roomId={roomId} deviceId={deviceId} projectId={projectId} roomType={ROOM_TYPES.DEVICE_MESSAGE} />
      </div>
    </RestrictedContent>
  );
};

const DeviceMessages = ({ deviceId, projectId, permissions, active }) => {
  if (isLoading(active)) return <Spinner />;

  if (!active) return <></>;

  return (
    <DeviceMessagesContent
      deviceId={deviceId}
      projectId={projectId}
      permissions={permissions}
    />
  );
};

const isEqual = (props1, props2) =>
  props1?.deviceId === props2?.deviceId &&
  props1?.active === props2?.active &&
  areDictsEqual(props1.permissions, props2.permissions);

export default memo(DeviceMessages, isEqual);
