import React, { createContext, useCallback, useState } from "react";

import ConfirmDialog from "../ui/dialogs/ConfirmDialog";

export const ConfirmDialogContext = createContext();

const ConfirmDialogProvider = ({ children }) => {
  const [title, setTitle] = useState();
  const [desc, setDesc] = useState();
  const [resolveReject, setResolveReject] = useState([]);
  const [resolve, reject] = resolveReject;

  const showConfirm = useCallback(
    ({ title, message }) =>
      new Promise((resolve, reject) => {
        setTitle(title);
        setDesc(message);
        setResolveReject([resolve, reject]);
      }),
    []
  );

  const onClose = useCallback(() => {
    setResolveReject([]);
  }, []);

  const onCancel = useCallback(() => {
    if (reject) reject();
    onClose();
  }, [reject, onClose]);

  const onConfirm = useCallback(() => {
    if (resolve) resolve();
    onClose();
  }, [resolve, onClose]);

  return (
    <>
      <ConfirmDialogContext.Provider value={showConfirm}>
        {children}
      </ConfirmDialogContext.Provider>
      <ConfirmDialog
        open={resolveReject.length === 2}
        title={title}
        description={desc}
        onConfirm={onConfirm}
        onClose={onCancel}
      />
    </>
  );
};

export default ConfirmDialogProvider;
