import React from "react";
import { Box, Drawer, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import CloseIcon from "@material-ui/icons/Close";

import { useProjectChatRoomId } from "../services/ChatService";
import { isLoading } from "../utils/uiUtils";
import { useMobileLayout } from "../hooks/uiHooks";

import ChatRoom from "./ChatRoom";
import Spinner from "./Spinner";
import { ROOM_TYPES } from "../services/ChatService";

const useStyles = makeStyles((theme) => {
  return {
    drawerPaper: {
      height: "100%",
    },
    base: (mobile) => ({
      display: "flex",
      flexDirection: "column",
      minWidth: mobile ? "inherit" : "400px",
      maxWidth: mobile ? "inherit" : "600px",
      height: "100%",
      width: "100%",
    }),
    iconButton: {
      padding: 0,
    },
  };
});

const ProjectActivityDrawer = ({ projectId, show, onClose }) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);
  const roomId = useProjectChatRoomId(projectId);

  const bar = (
    <Box display="flex" p={2}>
      <Box flexGrow={1}>
        <Typography variant="caption">Activity</Typography>
      </Box>
      <IconButton onClick={onClose} className={classes.iconButton}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
  );

  const content = isLoading(roomId) ? (
    <Spinner />
  ) : (
      <ChatRoom roomId={roomId} projectId={projectId} fullWidth={true} roomType={ROOM_TYPES.MEDIA_MESSAGE} />
  );

  return (
    <Drawer
      classes={{ paper: classes.drawerPaper }}
      anchor={mobile ? "bottom" : "right"}
      open={show}
      onClose={onClose}
    >
      <div className={classes.base}>
        {bar}
        {content}
      </div>
    </Drawer>
  );
};

export default ProjectActivityDrawer;
