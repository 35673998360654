import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";
import { useSnackbar } from "notistack";

import { joinProject } from "../../services/ApiService";
import { PROJECT_SORT_OPTION } from "../../utils/projectUtils";

import MainPage from "../MainPage";
import MetaTags from "../../ui/MetaTags";
import ProjectList from "../../ui/ProjectList";
import MyDialog from "../../ui/MyDialog";
import ProjectDialog from "../../ui/dialogs/ProjectDialog";
import ProjectPreviewJoinDialog from "../../ui/dialogs/ProjectPreviewJoinDialog";
import Spinner from "../../ui/Spinner";
import AddToQueueOutlinedIcon from "@material-ui/icons/AddToQueueOutlined";
import QueuePlayNextOutlinedIcon from "@material-ui/icons/QueuePlayNextOutlined";
import { ROUTES } from "../../route";
import { DefaultStrings, HomeStrings, ProjectStrings } from "../../strings";
import { PATTERN_ALPHANUMERIC } from "utils/generalUtils";
const ProjectsPage = ({ userId }) => {
  const matchJoin = useRouteMatch(ROUTES.PROJECT_JOIN);
  const matchNew = !!useRouteMatch(ROUTES.PROJECT_NEW);

  const [searchString, setSearchString] = useState(null);
  const [sortOption, setSortOption] = useState(null);
  const [sortDesc, setSortDesc] = useState(false);
  const [showJoinDialog, setShowJoinDialog] = useState(false);
  const [showCreateDialog, setShowCreateDialog] = useState(matchNew);
  const [showProjectList, setShowProjectList] = useState(true);
  const [editCode, setEditCode] = useState("");
  const [joinCode, setJoinCode] = useState(matchJoin && matchJoin.params.code);
  const { enqueueSnackbar } = useSnackbar();

  const configSortOptions = PROJECT_SORT_OPTION.map((opt) => ({
    label: ProjectStrings[opt],
    onClick: () => {
      if (sortOption === opt) {
        setSortDesc(!sortDesc);
      }
      setSortOption(opt);
    },
  }));

  const configPage = {
    appBar: {
      onSearch: (value) => {
        if (value && value.length > 1) setSearchString(value);
        else setSearchString(null);
      },
      sort: configSortOptions,
      title: DefaultStrings.TITLE,
    },

    actionButton: {
      actions: [
        {
          icon: <AddToQueueOutlinedIcon />,
          name: ProjectStrings.ADD_PROJECT_DIALOG_CREATE,
          onClick: () => {
            setShowCreateDialog(true);
          },
        },
        {
          icon: <QueuePlayNextOutlinedIcon />,
          name: ProjectStrings.ADD_PROJECT_DIALOG_JOIN,
          onClick: () => {
            setShowJoinDialog(true);
          },
        },
      ],
    },
  };

  const configProjectDialog = {
    onClose: () => {
      setShowCreateDialog(false);
    },
    onConfirm: () => {
      setShowCreateDialog(false);
      setShowProjectList(false);
    },
    onComplete: () => {
      setShowProjectList(true);
    },
  };

  const configProjectList = {
    filter: searchString,
    sortOption,
    sortDesc,
    userId,
  };

  const configJoinDialog = {
    icon: <QueuePlayNextOutlinedIcon />,
    title: ProjectStrings.ADD_PROJECT_DIALOG_JOIN,
    onClose: () => {
      setShowJoinDialog(false);
      setEditCode("");
    },
    onOk: () => {
      setJoinCode(editCode);
      setShowJoinDialog(false);
      setEditCode("");
    },
    disableOk: editCode.length < 6,
  };

  const configPreviewDialog = {
    code: joinCode,
    onClose: () => {
      setJoinCode(null);
      // setEditCode("");
      // setShowJoinDialog(false);
    },
    onConfirm: (projectId) => {
      // setEditCode("");
      // setShowJoinDialog(false);

      setShowProjectList(false);
      joinProject({ projectId, joinCode })
        .then(() => {
          enqueueSnackbar(ProjectStrings.ADD_PROJECT_JOIN_SUCCEED, { variant: "success" });
        })
        .catch((error) => {
          enqueueSnackbar(DefaultStrings.ERROR_MSG, {
            variant: "error",
          });
          console.error(error);
        })
        .finally(() => {
          setJoinCode(null);
          setShowProjectList(true);
        });
    },
  };

  const onUpdateProjectCode = e => {
    const input = e.target;
    if (input.validity.valid) setEditCode(input.value);
  };

  return (
    <>
      <MetaTags
        title={HomeStrings.META_TITLE_PROJECTS}
        description={HomeStrings.META_DESCRIPTION_PROJECTS}
      />
      <MainPage config={configPage}>
        {showProjectList ? (
          <ProjectList config={configProjectList} />
        ) : (
          <Spinner />
        )}
        <ProjectDialog
          userId={userId}
          open={showCreateDialog}
          config={configProjectDialog}
        />
        <MyDialog open={showJoinDialog} config={configJoinDialog}>
          <TextField
            label={ProjectStrings.ADD_PROJECT_DIALOG_ENTER_CODE}
            value={editCode}
            fullWidth
            onChange={onUpdateProjectCode}
            inputProps={{
              pattern: PATTERN_ALPHANUMERIC,
            }}
            autoFocus
          />
        </MyDialog>
        <ProjectPreviewJoinDialog config={configPreviewDialog} />
      </MainPage>
    </>
  );
};

export default ProjectsPage;
