import React, { useState, useEffect } from "react";
// import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useMobileLayout } from "../hooks/uiHooks";

import PullUpPanel from "./PullUpPanel";
import TeamCodeListCard from "./cards/TeamCodeListCard";
import TeamCodeGeneratorCard from "./cards/TeamCodeGeneratorCard";

const useStyles = makeStyles((theme) => {
  return {
    main: (mobile) => ({
      height: "100%",
      width: "100%",
      padding: theme.spacing(mobile ? 1 : 2),
      display: "flex",
      flexWrap: mobile ? "wrap" : "nowrap",
      alignItems: "stretch",
      overflowY: "auto",
    }),
    codes: (mobile) => ({
      width: mobile ? "100%" : 1,
      flexGrow: 2,
      padding: theme.spacing(1),
    }),
    generator: (mobile) => ({
      width: mobile ? "100%" : 1,
      flexGrow: 1,
      height: mobile ? "auto" : "100%",
      padding: theme.spacing(1),
    }),
  };
});

const TeamCodePanel = ({
  projectId,
  userId,
  open,
  canRead,
  canEdit,
  config,
}) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);
  const [newCode, setNewCode] = useState();

  useEffect(() => {
    if (!open) setNewCode(null);
  }, [open]);

  return (
    <PullUpPanel open={open} config={config}>
      <div className={classes.main}>
        <div className={classes.codes}>
          <TeamCodeListCard
            projectId={projectId}
            newCode={newCode}
            canEdit={canEdit}
          />
        </div>
        <div className={classes.generator}>
          <TeamCodeGeneratorCard
            projectId={projectId}
            setNewCode={setNewCode}
            canRead={canRead}
            canEdit={canEdit}
          />
        </div>
      </div>
    </PullUpPanel>
  );
};

export default TeamCodePanel;
