import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Tooltip } from "@material-ui/core";

import { useUserInfo } from "../services/UserService";

import logo from "../images/icon_my_player_v3.png";

const DEFAULT_SIZE = 9;

const useStyles = makeStyles((theme) => ({
  userAvatar: (size) => ({
    height: theme.spacing(size),
    width: theme.spacing(size),
  }),
}));

const UserAvatar = ({ userId, size = DEFAULT_SIZE }) => {
  const classes = useStyles(size);
  const userInfo = useUserInfo(userId);
  return (
    <Tooltip title={userInfo?.name ?? ""} placement="top">
      <Avatar
        className={classes.userAvatar}
        src={userInfo?.img || logo}
        alt={userInfo?.name}
      />
    </Tooltip>
  );
};

export default UserAvatar;
