import React, { useEffect, useState } from "react";
import { Box, Select, MenuItem, Avatar } from "@material-ui/core";
import { useParams, useRouteMatch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";

import MainPage from "../MainPage";
import ProjectTeamList from "../../ui/ProjectTeamList";
import ProfileDialog from "../../ui/dialogs/ProfileDialog";
import TeamCodePanel from "../../ui/TeamCodePanel";
import RestrictedContent from "../../ui/RestrictedContent";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";

import {
  useUserProjectPermissions,
  useProjectTitle,
} from "../../services/ProjectService";
import { useProjectInfo } from "../../hooks/projectHooks";
import { TEAM_SORT_OPTION } from "../../utils/teamUtils";
import * as Permissions from "../../utils/permissionUtils";
import { useMobileLayout } from "../../hooks/uiHooks";
import { updateUrl } from "../../services/UiService";
import { removeUser, updateUserRole } from "../../services/ApiService";

import {
  ROUTES,
  getProjectTeamRoute,
  getProjectTeamShareRoute,
} from "../../route";
import { DefaultStrings, ProfileStrings, ProjectStrings } from "../../strings";
import { useProjectTabs } from "./";

const useStyles = makeStyles((theme) => ({
  emptyMedia: (mobile) => ({
    width: mobile ? "100vw" : "100%",
    minHeight: mobile ? "100vw" : 240,
    fontSize: 80,
    fontWeight: 900,
  }),
  name: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: 16,
    fontWeight: 600,
  },
  permission: {
    fontSize: 12,
    color: "#747474",
    marginBottom: theme.spacing(1),
  },
}));

// Trello ticket on permissions: https://trello.com/c/dZzUlDeV/107-add-permission-control-for-project-team-page
const ProjectTeamPage = ({ userId }) => {
  const params = useParams();
  const projectId = params.projectId;
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);
  const { enqueueSnackbar } = useSnackbar();
  const matchManage = !!useRouteMatch([
    ROUTES.PROJECT_TEAM_MANAGE,
    ROUTES.PROJECT_TEAM_SHARE,
  ]);

  const [searchString, setSearchString] = useState(null);
  const [sortOption, setSortOption] = useState(null);
  const [sortDesc, setSortDesc] = useState(false);

  const projectInfo = useProjectInfo(projectId);
  const title = useProjectTitle(projectId);
  const tabs = useProjectTabs({
    projectId,
    tabKey: "TEAM",
  });

  const [showDialog, setShowDialog] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState();
  const [userImage, setUserImage] = useState();
  const [userNameLetters, setUserNameLetters] = useState();
  const [userName, setUserName] = useState();
  const [permissionName, setPermissionName] = useState();
  const [newPermissionName, setNewPermissionName] = useState();
  const [icon, setIcon] = useState();
  const [showPanel, setShowPanel] = useState(matchManage);
  const [progressId, setProgressId] = useState();

  // permissions control
  const permissions = useUserProjectPermissions({ userId, projectId });
  const canReadCode = Permissions.canReadCode(permissions);
  const canWriteCode = Permissions.canWriteCode(permissions);
  const canDeleteUsers = Permissions.canDeleteUsers(permissions);
  const canReadUsers = Permissions.canReadUsers(permissions);
  const canReadUserPermissions =
    Permissions.canReadUserPermissions(permissions);
  const canWriteUserPermissions =
    Permissions.canWriteUserPermissions(permissions);

  const isCreator = userId === projectInfo.creatorId;
  const selectedSelf = userId === selectedUserId;
  const selectedAdmin =
    permissionName ===
    Permissions.PERMISSION_NAMES[Permissions.PERMISSIONS.CREATOR];
  const canEditPermissionsSelected =
    !selectedSelf && (isCreator || (canWriteUserPermissions && !selectedAdmin));
  const canDeleteUserSelected =
    !selectedSelf && (isCreator || (canDeleteUsers && !selectedAdmin));

  useEffect(() => {
    setNewPermissionName(permissionName);
  }, [permissionName]);

  const onClickUser = (
    userId,
    userImage,
    userNameLetters,
    userName,
    permissionName,
    icon
  ) => {
    setSelectedUserId(userId);
    setUserImage(userImage);
    setUserNameLetters(userNameLetters);
    setUserName(userName);
    setPermissionName(permissionName);
    setIcon(icon);
    setShowDialog(true);
  };

  const onShowPanel = (show) => {
    setShowPanel(show);
    const url = show
      ? getProjectTeamShareRoute(projectId)
      : getProjectTeamRoute(projectId);
    updateUrl(url);
  };

  const getPermissionCode = (name) => {
    switch (name) {
      case "Admin":
        return Permissions.PERMISSIONS.CREATOR;
      case "Manager":
        return Permissions.PERMISSIONS.MANAGER;
      case "Viewer":
        return Permissions.PERMISSIONS.VIEWER;
      case "Content Only":
      default:
        return Permissions.PERMISSIONS.USER;
    }
  };

  const handlePermissionChange = (event) => {
    setNewPermissionName(event.target.value);
  };

  const ProfileDialogConfig = {
    // showButtonBar: canDeleteUserSelected,
    showButtonBar: true,
    overflow: [
      {
        label: ProfileStrings.PROFILE_REMOVE_TEAM_MEMBER,
        onClick: () => {
          setProgressId(selectedUserId);
          setShowDialog(false);
          removeUser({ userId: selectedUserId, projectId })
            .then(() => {
              enqueueSnackbar(ProjectStrings.TEAM_MEMBER_REMOVED, { variant: "success" });
            })
            .catch((error) => {
              enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
              console.warn(error);
            })
            .finally(() => {
              setProgressId(null);
            });
        },
        disabled: !canDeleteUserSelected,
      },
    ],
    disableOk: newPermissionName && permissionName === newPermissionName,
    onClose: () => {
      setShowDialog(false);
      setNewPermissionName(permissionName);
    },
    onOk: () => {
      setShowDialog(false);
      setProgressId(selectedUserId);
      updateUserRole({
        userId: selectedUserId,
        projectId,
        role: getPermissionCode(newPermissionName),
      }).finally(() => {
        setProgressId(null);

        // reset, but all these states should be one single object state
        setSelectedUserId(null);
        setUserImage(null);
        setUserNameLetters(null);
        setUserName(null);
        setPermissionName(null);
        setIcon(null);
        setNewPermissionName(null);
      });
    },
  };

  const configSortOptions = TEAM_SORT_OPTION.map((opt) => ({
    label: ProjectStrings[opt],
    onClick: () => {
      if (sortOption === opt) {
        setSortDesc(!sortDesc);
      }
      setSortOption(opt);
    },
  }));

  const config = {
    actionButton: {
      primary: {
        icon: <ShareOutlinedIcon />,
        name: ProjectStrings.TEAM_CODE_SHARE,
        onClick: () => {
          onShowPanel(true);
        },
        disabled: !canWriteCode,
      },
    },
    appBar: {
      tabDefault: "TEAM",
      tabs,
      onSearch:
        canReadUsers &&
        ((value) => {
          if (value && value.length > 1) setSearchString(value);
          else setSearchString(null);
        }),
      sort: canReadUsers && configSortOptions,
      overflow: [
        {
          label: ProjectStrings.TEAM_CODE_MANAGE,
          onClick: () => {
            onShowPanel(true);
          },
          disabled: !canReadCode,
        },
      ],
      title,
    },
    fixedHeight: true,
  };

  const configTeamList = {
    filter: searchString,
    sortOption,
    sortDesc,
    projectId,
    onClick: onClickUser,
    progressId,
  };

  const configPanel = {
    title: ProjectStrings.TEAM_CODE_MANAGE,
    onClose: () => onShowPanel(false),
  };

  const content = (
    <RestrictedContent permitted={canReadUsers}>
      <ProjectTeamList
        config={configTeamList}
        canReadPermission={canReadUserPermissions}
      />
    </RestrictedContent>
  );

  return (
    <MainPage config={config}>
      {content}
      <ProfileDialog
        userImage={userImage}
        open={showDialog}
        config={ProfileDialogConfig}
        titleChildren={
          userNameLetters && (
            <Avatar className={classes.emptyMedia} variant="square">
              {userNameLetters}
            </Avatar>
          )
        }
      >
        <Box pt={2}>
          {canReadUserPermissions && icon}
          <div className={classes.name}>{userName}</div>
          {canReadUserPermissions && (
            <>
              {canEditPermissionsSelected ? (
                <Select
                  value={newPermissionName}
                  onChange={handlePermissionChange}
                >
                  {Object.entries(Permissions.PERMISSION_NAMES).map(
                    ([key, value]) => {
                      return (
                        <MenuItem key={key} value={value}>
                          {value}
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              ) : (
                <div className={classes.permission}>{permissionName}</div>
              )}
            </>
          )}
        </Box>
      </ProfileDialog>
      <TeamCodePanel
        projectId={projectId}
        userId={userId}
        open={showPanel}
        canRead={canReadCode}
        canEdit={canWriteCode}
        config={configPanel}
      />
    </MainPage>
  );
};

export default ProjectTeamPage;
