import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";

import MainPage from "../MainPage";
import SystemEventList from "../../ui/SystemEventList";

import {
  updateUserNewsLastRead,
  useUserDisabledAllNews,
} from "../../services/UserService";
import { useUserEvents } from "../../services/SystemEventService";
import { isLoading } from "../../utils/uiUtils";

import MetaTags from "../../ui/MetaTags";

import { HomeStrings, NewsStrings } from "../../strings";

const useStyles = makeStyles((theme) => ({
  newsBase: ({ noEvent }) => ({
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    height: noEvent ? "100%" : "auto",
  }),
}));

const NewsPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const disabledAll = useUserDisabledAllNews();
  const lastEvent = useUserEvents({ limit: 1 });
  const noEvent = lastEvent === null;
  const classes = useStyles({ noEvent });

  // execute once on mount
  useEffect(() => {
    // 1s delay to avoid messing up reading of user news settings
    // otherwise will need to split categories and last read into separated documents
    setTimeout(updateUserNewsLastRead, 1000);
  }, []);

  useEffect(() => {
    if (disabledAll) {
      enqueueSnackbar(NewsStrings.NEWS_DISABLED_MSG, {
        variant: "warning",
      });
    }
  }, [disabledAll, enqueueSnackbar]);

  if (isLoading(lastEvent)) return <></>;

  const config = {
    fixedHeight: noEvent,
    appBar: {
      title: "News",
    },
  };

  return (
    <>
      <MetaTags
        title={HomeStrings.META_TITLE_NEWS}
        description={HomeStrings.META_DESCRIPTION_NEWS}
      />
      <MainPage config={config}>
        <div className={classes.newsBase}>
          <SystemEventList />
        </div>
      </MainPage>
    </>
  );
};

export default NewsPage;
