import { useEffect } from "react";

import { getDateOnlyTime } from "../utils/localeUtils";

const SCROLL_DELAY = 10;

// a util function to control the frequency of triggering a callback
export const debounce = (callback, wait) => {
  let timeout;
  return (...args) => {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => callback.apply(context, args), wait);
  };
};

export const getElementTop = (element) => element?.getBoundingClientRect().top;

// subscribe to the scroll event on an element
export const useElementOnScroll = ({
  element,
  onScroll,
  delay = SCROLL_DELAY,
}) => {
  useEffect(() => {
    if (!element || !onScroll) return;

    const onScrollInternal = (e) => onScroll(e.target);
    // use debounce to limit the event
    const debouncedOnScroll = debounce(onScrollInternal, delay);
    element.addEventListener("scroll", debouncedOnScroll);
    return () => {
      element.removeEventListener("scroll", debouncedOnScroll);
    };
  }, [element, delay, onScroll]);
};

export const updateUrl = (path) =>
  window.history.replaceState(null, null, path);

// insert date before messages with a different date
export const insertDateIntoMessages = ({
  messages,
  timestampKey,
  addAtEnd, // add date after message
}) => {
  // return if no message
  if (!messages || messages.length === 0) return messages;

  const res = messages.slice();
  let currentDate = messages[0][timestampKey];
  res.forEach((msg, index) => {
    const cd = new Date(currentDate).toDateString();
    const md = new Date(msg[timestampKey]).toDateString();
    if (cd !== md) {
      const addDate = addAtEnd ? currentDate : msg[timestampKey];
      res.splice(index, 0, getDateOnlyTime(addDate));
      currentDate = msg[timestampKey];
    }
  });
  if (addAtEnd) {
    res.push(getDateOnlyTime(messages[messages.length - 1][timestampKey]));
  } else {
    res.unshift(getDateOnlyTime(messages[0][timestampKey]));
  }
  return res;
};

export const addCookieBannerScript = () => {
  const script = document.createElement("script");
  script.src = `/cookieBanner.js?t=${Date.now()}`;
  document.body.appendChild(script);
};

export const changeThemeColor = (color) => {
  const meta = document.querySelector('meta[name="theme-color"]');
  if (meta) {
    meta.content = color;
  } else {
    const meta = document.createElement("meta");
    meta.name = "theme-color";
    meta.content = color;
    document.getElementsByTagName("head")[0].appendChild(meta);
  }
  // needed for ios
  document.body.style.background = color;
};
