import React from "react";
import {
  Box,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useMobileLayout } from "../hooks/uiHooks";

import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

import { DefaultStrings } from "../strings";

/**
 * sample config
 */
// const config = {
//   icon: <CloseIcon />,
//   title: "Title",
//   disableOk: false,
//   onClose: () => {},
//   onOk: () => {},
//   image: "imageURL",
//   actions: [
//     {
//       title: "Bold",
//       icon: <FormatBoldIcon />,
//       onClick: () => {},
//       active: true,
//     },
//   ],
//   large: false,
// };

const useStyles = makeStyles((theme) => {
  return {
    title: {
      display: "flex",
      alignItems: "center",
    },
    media: (mobile) => ({
      minHeight: mobile ? "100vw" : 360,
    }),
    actionsFiller: {
      flexGrow: 1,
    },
    actionActive: {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    iconWrapper: {
      display: "flex",
    },
  };
});

// Dialog component that provides custom: (in vertical order)
// 1) Image
// 2) Title with icon
// 3) Close and confirm buttons
// 4) Custom action buttons
const MyDialog = ({
  children,
  open,
  config: { icon, title, disableOk, onClose, onOk, image, actions, large, maximise = false },
}) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);

  const contentTitle = (icon || title) && (
    <DialogTitle className={classes.title} disableTypography>
      {icon && (
        <Box className={classes.iconWrapper} pr={2}>
          {icon}
        </Box>
      )}
      {title && <Typography variant="caption">{title}</Typography>}
    </DialogTitle>
  );

  const imageContent = image && (
    <CardMedia className={classes.media} image={image} />
  );

  const customActions = actions?.map((action) => (
    <Tooltip
      title={action.title}
      placement="top"
      key={`action-${action.title}`}
    >
      <IconButton
        onClick={action.onClick}
        classes={{ root: action.active && classes.actionActive }}
      >
        {action.icon}
      </IconButton>
    </Tooltip>
  ));

  const buttonConfirm = onOk && (
    <IconButton onClick={onOk} disabled={disableOk}>
      <CheckIcon />
    </IconButton>
  );

  // not using Tooltip if disabled
  const buttonConfirmTooltip =
    onOk &&
    (disableOk ? (
      buttonConfirm
    ) : (
      <Tooltip title={DefaultStrings.BUTTON_CONFIRM} placement="top">
        {buttonConfirm}
      </Tooltip>
    ));

  return (
    <Dialog
      fullWidth
      maxWidth={large ? "xl" : "xs"}
      open={open}
      onClose={onClose ? onClose : () => { } }
      fullScreen={mobile || maximise}
    >
      {imageContent}
      {contentTitle}
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {customActions}
        <div className={classes.actionsFiller} />
        {onClose && (
          <Tooltip title={DefaultStrings.BUTTON_CLOSE} placement="top">
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        )}
        {buttonConfirmTooltip}
      </DialogActions>
    </Dialog>
  );
};

export default MyDialog;
