import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormGroup, FormControlLabel, Switch } from "@material-ui/core";

import MyCard from "./MyCard";
import MyDialog from "../MyDialog";
import Spinner from "../Spinner";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";

import {
  useUserNewsCategories,
  updateUserNewsCategories,
} from "../../services/UserService";
import { getEventCategories } from "../../services/SystemEventService";
import { isLoading } from "../../utils/uiUtils";
import { areDictsEqual } from "../../utils/generalUtils";

import { ProfileStrings } from "../../strings";

const useStyles = makeStyles((theme) => ({
  notiDialogBase: {
    display: "flex",
    flexDirection: "column",
  },
  notiSetting: {
    borderBottom: `solid 1px ${theme.palette.border}`,
    padding: theme.spacing(2),
    "&:last-child": {
      borderBottom: "none",
    },
  },
  notiLabel: {
    flexGrow: 1,
  },
  notiLabelPlacement: {
    marginRight: 0,
  },
}));

const Setting = ({ name, checked, onChange }) => {
  const classes = useStyles();

  const onChangeInternal = () => {
    onChange(name);
  };

  return (
    <FormControlLabel
      labelPlacement="start"
      control={
        <Switch
          checked={checked}
          onChange={onChangeInternal}
          name={name}
          color="primary"
        />
      }
      label={ProfileStrings.PROFILE_NOTI_CATEGORIES[name]}
      classes={{
        label: classes.notiLabel,
        labelPlacementStart: classes.notiLabelPlacement,
      }}
      className={classes.notiSetting}
    />
  );
};

const NotificationsDialog = ({ open, onOk, onClose, settings }) => {
  const classes = useStyles();
  const [settingsEdit, setSettingsEdit] = useState(settings);
  const categories = getEventCategories();

  const onChange = (name) => {
    setSettingsEdit({
      ...settingsEdit,
      [name]: !settingsEdit[name],
    });
  };

  const configDialog = {
    icon: <NotificationsNoneOutlinedIcon />,
    title: ProfileStrings.PROFILE_NOTI_TITLE,
    onOk: () => {
      onOk(settingsEdit);
    },
    onClose: () => {
      onClose();
      setSettingsEdit(settings);
    },
    disableOk: areDictsEqual(settings, settingsEdit),
  };

  return (
    <MyDialog open={open} config={configDialog}>
      <FormGroup row className={classes.notiDialogBase}>
        {categories.map((name) => (
          <Setting
            key={name}
            name={name}
            checked={settingsEdit?.[name] ?? false}
            onChange={onChange}
          />
        ))}
      </FormGroup>
    </MyDialog>
  );
};

const NotificationsCard = () => {
  const [showDialog, setShowDialog] = useState(false);

  const settings = useUserNewsCategories();

  const onDialogOk = (settingsNew) => {
    updateUserNewsCategories(settingsNew);
    setShowDialog(false);
  };

  const onDialogClose = () => {
    setShowDialog(false);
  };

  const configCard = {
    icon: <NotificationsNoneOutlinedIcon />,
    title: ProfileStrings.PROFILE_NOTI_TITLE,
    onClick: () => {
      setShowDialog(true);
    },
  };

  if (isLoading(settings)) return <Spinner />;

  return (
    <>
      <MyCard config={configCard} canRead={true} canEdit={true}>
        {ProfileStrings.PROFILE_NOTI_MESSAGE}
      </MyCard>
      <NotificationsDialog
        open={showDialog}
        onOk={onDialogOk}
        onClose={onDialogClose}
        settings={settings}
      />
    </>
  );
};

export default NotificationsCard;
