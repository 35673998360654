import React, { useState } from "react";
import { useSnackbar } from "notistack";
import {
  Box,
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { usePermissionsBasic } from "../../services/PermissionService";
import { generateProjectCode } from "../../services/ApiService";

import ButtonCard from "./ButtonCard";
import CasinoOutlinedIcon from "@material-ui/icons/CasinoOutlined";

import { DefaultStrings, ProjectStrings } from "../../strings";

const desc = ProjectStrings.TEAM_CODE_GENERATE_DESC;

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
}));

const TeamCodeGeneratorCard = ({ projectId, setNewCode, canRead, canEdit }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const permissions = usePermissionsBasic(projectId);

  const [name, setName] = useState("");
  const [role, setRole] = useState(0); // index as value
  const [progress, setProgress] = useState(false);

  const onGenerate = () => {
    setProgress(true);
    generateProjectCode({
      projectId,
      name,
      defaultRole: permissions[role].permissionsKey,
    })
      .then((code) => {
        enqueueSnackbar(ProjectStrings.TEAM_CODE_GENERATED, {
          variant: "success",
        });
        setNewCode(code);

        setName("");
        setRole(0);
      })
      .catch((err) => {
        enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
        console.warn(err);
      })
      .finally(() => setProgress(false));
  };

  const configCard = {
    icon: <CasinoOutlinedIcon />,
    title: ProjectStrings.TEAM_CODE_GENERATE_TITLE,
    buttonLabel: ProjectStrings.TEAM_CODE_GENERATE_BUTTON,
    desc,
    onClick: () => {
      onGenerate();
    },
    disableButton: !canEdit || name.length < 3,
    progress,
  };

  return (
    <ButtonCard config={configCard} canRead={canRead}>
      <FormGroup>
        <Box p={1}>
          <FormControl className={classes.formControl}>
            <TextField
              label={ProjectStrings.TEAM_CODE_NAME}
              value={name}
              fullWidth
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
        </Box>
        <Box p={1}>
          <FormControl variant="filled" className={classes.formControl}>
            <InputLabel>{ProjectStrings.TEAM_CODE_DEFAULT_ROLE}</InputLabel>
            <Select value={role} onChange={(e) => setRole(e.target.value)}>
              {permissions &&
                permissions.map((p, i) => (
                  <MenuItem key={`option-${i}`} value={i}>
                    {p.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
      </FormGroup>
    </ButtonCard>
  );
};

export default TeamCodeGeneratorCard;
