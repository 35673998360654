import React, {useState} from "react";
import {useSnackbar} from "notistack";
import {
  Box,
  Button,
  Card,
  IconButton,
  ListSubheader,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import {isCodeMutable} from "../../utils/teamUtils";
import {
  useProjectShareCodeInfo,
  useProjectShareCodes,
  useProjectShareCodeMembers,
} from "../../services/ProjectService";
import {useMobileLayout, useConfirm} from "../../hooks/uiHooks";
import {copyText} from "../../utils/generalUtils";
import {isLoading} from "../../utils/uiUtils";
import {deleteProjectCode} from "../../services/ApiService";

import Empty from "../Empty";
import Spinner from "../Spinner";
import UserAvatar from "../UserAvatar";
import MoreIcon from "@material-ui/icons/MoreVert";
import LinkIcon from "@material-ui/icons/Link";

import {DefaultStrings, ProjectStrings} from "../../strings";

import {getProjectJoinUrl} from "../../route";

const useStyles = makeStyles((theme) => ({
  main: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1),
    overflowY: "auto",
  },
  codeMain: ({highlight, mobile}) => ({
    backgroundColor: highlight
      ? theme.palette.primary.main
      : theme.palette.background.default,
    margin: theme.spacing(1),
    display: "flex",
    flexDirection: mobile ? "column" : "row",
  }),
  iconMore: {
    height: "40px",
    width: "40px",
  },
  codeLeft: ({mobile}) => ({
    width: mobile ? "90px" : "120px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRight: "solid 1px #D0D0D0",
    padding: theme.spacing(1),
  }),
  codeRight: {
    width: 1,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  codeHeader: {
    display: "flex",
    alignItems: "center",
    padding: "8px 16px 0px 16px",
  },
  codeInfo: {
    display: "flex",
    padding: "0px 16px 8px 16px",
  },
  codeButton: ({mobile}) => ({
    width: mobile ? "auto" : "150px",
    whiteSpace: mobile ? "auto" : "nowrap",
    margin: theme.spacing(1),
    marginTop: 0,
  }),
  codeTeam: ({mobile}) => ({
    display: "flex",
    flexDirection: "column",
    borderTop: "solid 1px #D0D0D0",
    padding: mobile
      ? theme.spacing(1)
      : `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    overflowX: "auto",
  }),
}));

const CodeRow = ({code, highlight, canEdit}) => {
  const mobile = useMobileLayout();
  const classes = useStyles({highlight, mobile});
  const info = useProjectShareCodeInfo(code);
  const [anchor, setAnchor] = useState(null);
  const {enqueueSnackbar} = useSnackbar();
  const members = useProjectShareCodeMembers(code);
  const confirm = useConfirm();

  const canDelete = isCodeMutable(info) && canEdit;
  const avatarCreatorSize = mobile ? 8 : 9;
  const avatarTeamSize = mobile ? 7 : 8;

  const nameRole =
    info?.name && info?.defaultRoleName ? (
      <div style={{flexGrow: 1, display: "flex", flexDirection: "column"}}>
        <Typography variant="caption">{info?.name}</Typography>
        <span>
          {ProjectStrings.TEMA_CODE_CARD_DEFAULT_ROLE}
          {info?.defaultRoleName}
        </span>
      </div>
    ) : (
      <span style={{flexGrow: 1}}>
        {ProjectStrings.TEMA_CODE_CARD_LEGACY_CODE}
      </span>
    );

  const teamContent = members && (
    <div className={classes.codeTeam}>
      <span>
        {ProjectStrings.TEAM_CODE_TEAM} ({members?.length})
      </span>
      <div style={{display: "flex"}}>
        {members.map((m) => (
          <Box m={1} key={m.usersId}>
            <UserAvatar userId={m.usersId} size={avatarTeamSize} />
          </Box>
        ))}
      </div>
    </div>
  );

  const onDelete = () => {
    setAnchor(null);
    const title = `${ProjectStrings.TEAM_CODE_DELETE_CONFIRM_TITLE} (${code})`;
    confirm({
      title,
      message: ProjectStrings.TEAM_CODE_DELETE_CONFIRM_DESC,
    })
      .then(() => {
        deleteProjectCode({projectId: info.projectId, code})
          .then(() => {
            enqueueSnackbar(ProjectStrings.TEAM_CODE_DELETED, {
              variant: "success",
            });
          })
          .catch((error) => {
            enqueueSnackbar(DefaultStrings.ERROR_MSG, {variant: "error"});
            console.warn(error);
          });
      })
      .catch(() => {});
  };

  const onCopy = async (text, message) => {
    try {
      await copyText(text);
      enqueueSnackbar(message, {variant: "info"});
    } catch (error) {
      console.error(error);
      enqueueSnackbar(DefaultStrings.ERROR_MSG, {variant: "error"});
    }
  };

  const onCopyCode = () => {
    onCopy(code, ProjectStrings.TEAM_CODE_COPIED);
    setAnchor(null);
  };

  const onCopyLink = () => {
    onCopy(getProjectJoinUrl(code), ProjectStrings.TEAM_CODE_LINK_COPIED);
    setAnchor(null);
  };

  const buttonsContent = (
    <>
      <Button
        variant="contained"
        className={classes.codeButton}
        onClick={onCopyCode}
      >
        {ProjectStrings.TEAM_CODE_CARD_COPY_CODE}
      </Button>
      <Button
        variant="contained"
        className={classes.codeButton}
        onClick={onCopyLink}
      >
        {ProjectStrings.TEAM_CODE_CARD_COPY_LINK}
      </Button>
    </>
  );

  const menuItemDelete = (
    <MenuItem onClick={onDelete} disabled={!canDelete}>
      {ProjectStrings.TEAM_CODE_MENU_DELETE}
    </MenuItem>
  );

  const menuDesktopContent = (
    <Menu
      id="menuOverflow"
      anchorEl={anchor}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={anchor !== null}
      onClose={(e) => setAnchor(null)}
    >
      {menuItemDelete}
    </Menu>
  );

  const menuMobileContent = (
    <Menu
      id="menuOverflow"
      anchorEl={anchor}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={anchor !== null}
      onClose={(e) => setAnchor(null)}
    >
      <ListSubheader>
        {ProjectStrings.TEAM_CODE_CARD_CAPTION_COPY}
      </ListSubheader>
      <MenuItem onClick={onCopyCode}>
        {ProjectStrings.TEAM_CODE_CARD_COPY_CODE}
      </MenuItem>
      <MenuItem onClick={onCopyLink}>
        {ProjectStrings.TEAM_CODE_CARD_COPY_LINK}
      </MenuItem>
      <ListSubheader>
        {ProjectStrings.TEAM_CODE_CARD_CAPTION_MANAGE}
      </ListSubheader>
      {menuItemDelete}
    </Menu>
  );

  const menuContent = (
    <>
      <IconButton
        classes={{root: classes.iconMore}}
        color="inherit"
        onClick={(e) => setAnchor(e.currentTarget)}
      >
        <MoreIcon />
      </IconButton>
      {mobile ? menuMobileContent : menuDesktopContent}
    </>
  );

  const creatorContent = (
    <UserAvatar userId={info?.creatorId} size={avatarCreatorSize} />
  );

  const headerContent = (
    <>
      <LinkIcon />
      <Typography variant="caption" style={{flexGrow: 1, marginLeft: "8px"}}>
        {code}
      </Typography>
    </>
  );

  const desktopLayout = (
    <div className={classes.codeMain}>
      <div className={classes.codeLeft}>{creatorContent}</div>
      <div className={classes.codeRight}>
        <div className={classes.codeHeader}>
          {headerContent}
          {menuContent}
        </div>
        <div className={classes.codeInfo}>
          {nameRole}
          {buttonsContent}
        </div>
        {teamContent}
      </div>
    </div>
  );

  const mobileLayout = (
    <div className={classes.codeMain}>
      <div style={{display: "flex"}}>
        <div className={classes.codeLeft}>{creatorContent}</div>
        <div className={classes.codeRight}>
          <div className={classes.codeHeader}>
            {headerContent}
            {menuContent}
          </div>
          <div className={classes.codeInfo}>{nameRole}</div>
        </div>
      </div>
      {teamContent}
    </div>
  );

  return mobile ? mobileLayout : desktopLayout;
};

const TeamCodeListCard = ({projectId, newCode, canEdit}) => {
  const classes = useStyles();
  const codes = useProjectShareCodes(projectId);

  const loading = isLoading(codes);
  const empty = !codes || codes.length === 0;

  return (
    <Card className={classes.main}>
      {loading ? (
        <Spinner />
      ) : empty ? (
        <Empty />
      ) : (
        codes.map((code) => (
          <CodeRow
            key={`code-${code}`}
            code={code}
            highlight={newCode === code}
            canEdit={canEdit}
          />
        ))
      )}
    </Card>
  );
};

export default TeamCodeListCard;
