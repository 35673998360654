import React, { useEffect, useRef, useState, useCallback } from "react";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";

import {
  useProjectIdWithCode,
  useProjectInfo,
  useUserInProject,
} from "../../services/ProjectService";
import { previewProject } from "../../services/ApiService";

import ProjectPreviewDialog from "./ProjectPreviewDialog";

import { getProjectOverviewRoute } from "../../route";
import { DefaultStrings, ProjectStrings } from "../../strings";

const useStyles = makeStyles((theme) => {
  return {
    button: {
      width: "60%",
      margin: theme.spacing(1),
      "&:last-child": {
        marginTop: 0,
      },
    },
  };
});

/**
 * sample config
 */
// const config = {
//   code: "code",
//   onClose: () => {},
//   onConfirm: () => {},
// }

// a change of code will trigger open
const ProjectPreviewJoinDialog = ({ config: { code, onClose, onConfirm } }) => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const projectId = useProjectIdWithCode(code);
  const joined = useUserInProject({ projectId });
  const projectInfo = useProjectInfo({ projectId: joined ? projectId : null });

  const [info, setInfo] = useState();
  const mounted = useRef(false);

  const onCloseInternal = useCallback(() => {
    setInfo(undefined);
    if (onClose) onClose();
  }, [onClose]);

  // check if code is valid
  useEffect(() => {
    if (!code) {
      // close
      onCloseInternal();
    } else if (projectId === null) {
      // invalid
      enqueueSnackbar(ProjectStrings.ADD_PROJECT_JOIN_ERROR, {
        variant: "error",
      });
    }
  }, [code, projectId, enqueueSnackbar, onCloseInternal]);

  // close dialog and redirect to existing project
  useEffect(() => {
    if (joined && projectInfo) {
      onCloseInternal();
      history.push(getProjectOverviewRoute(projectInfo.projectId));
      enqueueSnackbar(ProjectStrings.ADD_PROJECT_JOIN_JOINED_ALREADY, {
        variant: "info",
      });
    }
  }, [joined, projectInfo, history, enqueueSnackbar, onCloseInternal]);

  // set info with new project
  useEffect(() => {
    mounted.current = true;

    if (projectId && code && joined === false) {
      previewProject({ projectId, code })
        .then((result) => {
          // only when result.success is true means the result is valid
          if (result.success !== true) {
            throw new Error(result.errors);
          } else {
            if (mounted.current) {
              setInfo(result);
            }
          }
        })
        .catch((error) => {
          console.warn(error);
          // invalid
          enqueueSnackbar(ProjectStrings.ADD_PROJECT_JOIN_ERROR, {
            variant: "error",
          });
          if (mounted.current) {
            onCloseInternal();
          }
        });
    }

    return () => {
      mounted.current = false;
    };
  }, [projectId, code, joined, enqueueSnackbar, onCloseInternal]);

  if (!code || projectId === null || info === null || joined) return <></>;

  const onConfirmInternal = () => {
    if (onConfirm) onConfirm(projectId);
  };

  const config = {
    info,
    onClose: onCloseInternal,
    loading: !info,
  };

  return (
    <ProjectPreviewDialog config={config}>
      <div className={classes.button}>
        <Button variant="contained" fullWidth onClick={onCloseInternal}>
          {DefaultStrings.BUTTON_CANCEL}
        </Button>
      </div>
      <div className={classes.button}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={onConfirmInternal}
        >
          {ProjectStrings.ADD_PROJECT_JOIN_BUTTON}
        </Button>
      </div>
    </ProjectPreviewDialog>
  );
};

export default ProjectPreviewJoinDialog;
