import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useMobileLayout } from "../hooks/uiHooks";

import MobileContent from "./MobileContent";

import KeyboardBackspaceOutlinedIcon from "@material-ui/icons/KeyboardBackspaceOutlined";
import ArrowUpwardOutlinedIcon from "@material-ui/icons/ArrowUpwardOutlined";

const useStyles = makeStyles((theme) => {
  return {
    placeholder: {
      position: "relative",
      width: "100%",
      height: "100%",
    },
    content: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: `translate(-50%, -50%)`,
      whiteSpace: "nowrap",
      textAlign: "center",
    },
    row: {
      display: "flex",
      alignItems: "center",
    },
  };
});

const InstructionContent = ({ title, desc, arrow = "left" }) => {
  const classes = useStyles();
  const mobile = useMobileLayout();

  const mobileContent = <MobileContent title={title} desc={desc} />;

  const desktopContent = (
    <div className={classes.content}>
      <Box mb={2}>
        <Typography variant="caption">{title}</Typography>
      </Box>
      <div className={classes.row}>
        {arrow === "up" ? (
          <ArrowUpwardOutlinedIcon color="primary" fontSize="small" />
        ) : (
          <KeyboardBackspaceOutlinedIcon color="primary" fontSize="small" />
        )}
        <Box mr={1} />
        <Typography variant="body1" color="textSecondary">
          {desc}
        </Typography>
      </div>
    </div>
  );

  return (
    <div className={classes.placeholder}>
      {mobile ? mobileContent : desktopContent}
    </div>
  );
};
export default InstructionContent;
