import React, { memo } from "react";
import {
  Card,
  CardActionArea,
  CardMedia,
  Typography,
  LinearProgress,
  IconButton,
  CardActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import { PushPin, PushPinOutlined } from "@material-ui/icons";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";

import OverflowTypography from "../OverflowTypography";

import { useUserName, useProjectUsersCount } from "../../hooks/userHooks";
import { useMobileLayout } from "../../hooks/uiHooks";
import { areDictsEqual } from "../../utils/generalUtils";
import { useProjectPinned, pinProject } from "../../services/UserService";

import { DefaultStrings } from "../../strings";
import { getProjectOverviewRoute } from "../../route";

/*
 sample config
 */
// const config = {
//   showBrand: true,
//   showDescription: true,
//   showParticipants: true,
//   showPin: true,
//   disableShadow: false,
//   useUnread: true,
//   loading: false,
//   onClick: (projectId) => {}
// };

const useStyles = makeStyles((theme) => ({
  card: ({ disableShadow }) => {
    const res = {
      height: "100%",
      position: "relative",
      flexGrow: 1,
    };
    if (disableShadow) res.boxShadow = "none;";
    return res;
  },
  action: ({ clickable }) => ({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    padding: clickable ? "inherit" : 0,
    "&:last-child": {
      paddingBottom: theme.spacing(2),
    },
  }),
  media: {
    flexGrow: 2,
    height: 1,
  },
  content: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  name: {
    paddingTop: theme.spacing(1),
  },
  participants: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  desc: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    flexGrow: 1,
    height: 1,
    whiteSpace: "pre-line",
  },
  hidden: {
    visibility: "hidden",
  },
  pinnedIconButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 1,
  },
  pinnedIcon: {
    filter: "drop-shadow( 0 0 3px rgba(0, 0, 0, .7))",
  },
  unpinnedIcon: {
    filter: "drop-shadow( 0 0 3px white)",
  },
  cardAnchor: {
    textDecoration: "none",
  },
  textPrimary: {
    color: theme.palette.text.primary,
  },
}));

const getProjectParticipants = (userCount) => {
  if (userCount && userCount > 1) {
    return ` and ${userCount - 1} other${userCount > 2 ? "s" : ""}`;
  }
  return null;
};

const UserName = ({ userId }) => {
  return useUserName(userId);
};

const areCardsEqual = (props1, props2) =>
  areDictsEqual(props1.info, props2.info) &&
  areDictsEqual(props1.config, props2.config);

const ProjectCard = memo(
  ({
    projectId,
    info,
    config: {
      showBrand,
      showDescription,
      showParticipants,
      showPin,
      disableShadow,
      onClick,
      clickToOpen,
      loading,
    },
  }) => {
    const mobile = useMobileLayout();
    const usersCount = useProjectUsersCount(projectId);
    const clickable = onClick || clickToOpen;

    const classes = useStyles({ mobile, disableShadow, clickable });
    const name = info && info.name ? info.name : DefaultStrings.APP_NAME;
    const participants = getProjectParticipants(usersCount);
    const brand = info && info.brand ? info.brand : "";
    const desc = info && info.desc ? info.desc : "";
    const pinned = useProjectPinned(projectId);

    if (!info) return null;

    const CardComponent = clickable ? CardActionArea : CardActions;

    const onClickInternal = () => {
      if (clickToOpen) return;
      if (onClick) onClick(projectId);
    };

    const onPinnedHandler = (event) => {
      event.stopPropagation();
      event.preventDefault();
      pinProject({ projectId, pin: !pinned });
    };

    const pinnedContent = showPin && (
      <IconButton
        onClick={onPinnedHandler}
        className={classes.pinnedIconButton}
      >
        {pinned ? (
          <PushPin htmlColor="#FFFFFF" className={classes.pinnedIcon} />
        ) : (
          <PushPinOutlined
            htmlColor="#BFC2D0"
            className={classes.unpinnedIcon}
          />
        )}
      </IconButton>
    );

    const mediaContent = info && info.imageURL && (
      <CardMedia className={classes.media} image={info.imageURL} />
    );
    const nameContent = (
      <OverflowTypography
        variant="h6"
        color="textPrimary"
        noWrap
        className={clsx(classes.name, classes.content)}
      >
        {name}
      </OverflowTypography>
    );
    const brandContent = showBrand && (
      <OverflowTypography
        variant="body2"
        color="textSecondary"
        className={clsx(classes.content, !brand && classes.hidden)}
        noWrap
      >
        {brand || "_"}
      </OverflowTypography>
    );
    const participantsContent = showParticipants && (
      <div className={clsx(classes.participants, classes.content)}>
        <PermIdentityOutlinedIcon className={classes.textPrimary} />
        <Typography variant="body2" color="textSecondary">
          <UserName userId={info.creatorId} />
          {participants}
        </Typography>
      </div>
    );
    const descContent = showDescription && (
      <Typography
        variant="body2"
        color="textSecondary"
        className={clsx(classes.desc, classes.content)}
      >
        {desc}
      </Typography>
    );

    const allContent = (
      <>
        {mediaContent}
        {loading && <LinearProgress />}
        {nameContent}
        {brandContent}
        {participantsContent}
        {descContent}
      </>
    );

    const cardContent = (
      <Card key={`ProjectCard-${projectId}`} className={classes.card}>
        {pinnedContent}
        {!loading ? (
          <CardComponent className={classes.action} onClick={onClickInternal}>
            {allContent}
          </CardComponent>
        ) : (
          <div className={classes.action}>{allContent}</div>
        )}
      </Card>
    );

    return clickToOpen ? (
      <a
        className={`${classes.cardAnchor} ${classes.textPrimary}`}
        href={getProjectOverviewRoute(projectId)}
      >
        {cardContent}
      </a>
    ) : (
      cardContent
    );
  },
  areCardsEqual
);

export default ProjectCard;
