import React, { useState } from "react";
import {
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Toolbar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import Search from "./Search";

import OverflowTypography from "./OverflowTypography";
import MoreIcon from "@material-ui/icons/MoreVert";
import SortIcon from "@material-ui/icons/Sort";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

import logo from "../images/icon_my_player_v3.png";
import { DefaultStrings } from "../strings";

/*
 sample config
 */
// const config = {
//   title: DefaultStrings.TITLE,
//   onSearch: (value) => {
//     console.debug("onSearch", value);
//   },
//   sort: [
//     {
//       label: "Most Recent",
//       onClick: () => {
//         console.debug("Most Recent");
//       },
//       selected: true,
//       desc: true,
//     },
//     {
//       label: "Name",
//       onClick: () => {
//         console.debug("Name");
//       },
//     },
//   ],
//   extras: [
//     {
//       icon: <EditIcon />,
//       name: "Edit",
//       onClick: () => {
//         console.debug("Edit");
//       },
//     },
//   ],
//   overflow: [
//     {
//       label: "Logout",
//       onClick: () => {
//         console.debug("Logout");
//       },
//       disabled: false,
//     },
//   ],
//   tabs: [
//     {
//       label: "Overview",
//       onClick: () => {
//         console.debug("Overview");
//       },
//     },
//     {
//       label: "Media",
//       onClick: () => {
//         console.debug("Media");
//       },
//     },
//   ],
// };

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    minHeight: 48,
    height: "100%",
    alignItems: "flex-start",
  },
  title: {
    flexGrow: 1,
    alignSelf: "center",
    fontWeight: "bold",
  },
  icon: {
    width: 24,
    alignSelf: "center",
    marginRight: theme.spacing(3),
  },
  tab: {
    minWidth: 120,
    textTransform: "capitalize",
  },
  sortIcon: {
    display: "flex",
    marginLeft: theme.spacing(1),
  },
  sortSelected: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const Title = ({ title }) => {
  const classes = useStyles();
  return (
    <>
      <img
        src={logo}
        edge="start"
        className={classes.icon}
        alt={DefaultStrings.APP_NAME}
      />
      <OverflowTypography className={classes.title} variant="h6" noWrap>
        {title}
      </OverflowTypography>
    </>
  );
};

const TabBar = ({ mobile, currentTab, onTabChange, tabs }) => {
  const classes = useStyles();
  return (
    <Tabs
      name="tab"
      centered={mobile ? false : true}
      value={currentTab >= 0 ? currentTab : false}
      onChange={onTabChange}
      variant={mobile ? "scrollable" : "standard"}
      indicatorColor="primary"
      textColor="primary"
      scrollButtons={mobile ? "on" : "auto"}
    >
      {tabs.map((tab) => (
        <Tab className={classes.tab} key={tab.label} label={tab.label} />
      ))}
    </Tabs>
  );
};

const MyAppBar = ({ mobile, config }) => {
  const hasSearch = !!config?.onSearch;
  const hasOverflow = config?.overflow?.length > 0;
  const hasSort = config?.sort?.length > 0;
  const hasTabs = config?.tabs?.length > 0;
  const hasExtra = config?.extras?.length > 0;
  const title = config?.title;
  const tabIndex = config?.tabs?.findIndex((t) => t.key === config?.tabDefault);

  const [anchorSort, setAnchorSort] = useState(null);
  const [anchorOverflow, setAnchorOverflow] = useState(null);
  const [showTab] = useState(true);
  const [searching, setSearching] = useState(false);
  const classes = useStyles();

  const onOverflowAction = (func) => {
    setAnchorOverflow(null);
    if (func) func();
  };

  const onSort = (func) => {
    setAnchorSort(null);
    if (func) func();
  };

  const onTabChange = (event, newTab) => {
    if (config.tabs[newTab].onClick) {
      config.tabs[newTab].onClick(tabIndex, newTab);
    }
  };

  const onSearch = (value) => {
    setSearching(value !== null);
    if (config.onSearch) config.onSearch(value);
  };

  return (
    <AppBar color="inherit" position="sticky">
      <Toolbar className={classes.toolbar}>
        {/* Title */}
        {(!mobile || !searching) && <Title title={title} />}
        {/* Search */}
        {hasSearch && <Search mobile={mobile} onSearch={onSearch} />}
        {(!mobile || !searching) && (
          <>
            {/* Sort */}
            {hasSort && (
              <>
                <IconButton
                  aria-label={DefaultStrings.BUTTON_SORT}
                  color="inherit"
                  onClick={(e) => setAnchorSort(e.currentTarget)}
                >
                  <SortIcon />
                </IconButton>
                <Menu
                  id="menuSort"
                  anchorEl={anchorSort}
                  keepMounted
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  open={anchorSort !== null}
                  onClose={(e) => setAnchorSort(null)}
                >
                  {config.sort.map((action) => (
                    <MenuItem
                      key={action.label}
                      onClick={(e) => onSort(action.onClick)}
                      className={clsx(
                        action.selected && classes.sortSelected
                      )}
                    >
                      <span>{action.label}</span>
                      {action.selected && (
                        <div className={classes.sortIcon}>
                          {action.desc ? (
                            <ArrowDownwardIcon />
                          ) : (
                            <ArrowUpwardIcon />
                          )}
                        </div>
                      )}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
            {/* Extra */}
            {hasExtra && (
              <>
                {config.extras.map((extra) => (
                  <IconButton
                    key={extra.name}
                    aria-label={extra.name}
                    color="inherit"
                    onClick={(e) => extra.onClick()}
                  >
                    {extra.icon}
                  </IconButton>
                ))}
              </>
            )}
            {/* Overflow */}
            {hasOverflow && (
              <>
                <IconButton
                  aria-label={DefaultStrings.BUTTON_OVERFLOW}
                  edge="end"
                  color="inherit"
                  onClick={(e) => setAnchorOverflow(e.currentTarget)}
                >
                  <MoreIcon />
                </IconButton>
                <Menu
                  id="menuOverflow"
                  anchorEl={anchorOverflow}
                  keepMounted
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  open={anchorOverflow !== null}
                  onClose={(e) => setAnchorOverflow(null)}
                >
                  {config.overflow.map((action) => (
                    <MenuItem
                      key={action.label}
                      onClick={(e) => onOverflowAction(action.onClick)}
                      disabled={action.disabled}
                    >
                      {action.label}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </>
        )}
      </Toolbar>
      {showTab && hasTabs && (
        <TabBar
          mobile={mobile}
          currentTab={tabIndex}
          onTabChange={onTabChange}
          tabs={config.tabs}
        />
      )}
    </AppBar>
  );
};

export default MyAppBar;
