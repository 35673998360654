import React, { useEffect, useRef, useState } from "react";
import { useFirebase } from "react-redux-firebase";
import { useSnackbar } from "notistack";
import { TextField } from "@material-ui/core";

import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import MyCard from "./MyCard";
import { ProfileStrings } from "../../strings";
import MyDialog from "../MyDialog";
import PasswordField from "../PasswordField";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexWrap: "nowrap",
    marginBottom: theme.spacing(1),
  },
  header: {
    color: "black",
    fontWeight: "bold",
    marginRight: theme.spacing(0.5),
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
  },
  dialogEmail: {
    fontWeight: "bold",
    width: "100%",
  },
}));

const UserAccountCard = () => {
  const classes = useStyles();
  const firebase = useFirebase();
  const user = firebase.auth().currentUser;
  const userEmail = user?.email;
  const [showDialog, setShowDialog] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatedPassword, setRepeatedPassword] = useState("");
  const [currentPasswordErrorText, setCurrentPasswordErrorText] = useState("");
  const [newPasswordErrorText, setNewPasswordErrorText] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const mounted = useRef(true);

  // this is necessary to avoid state update after unmounted
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const configCard = {
    icon: <PermIdentityIcon />,
    title: ProfileStrings.PROFILE_SECURITY_CARD_TITLE,
    onClick: () => {
      setShowDialog(true);
    },
  };

  const configDialog = {
    icon: <PermIdentityIcon />,
    title: ProfileStrings.PROFILE_SECURITY_CARD_TITLE,
    disableOk:
      currentPassword === "" || newPassword === "" || repeatedPassword === "",
    onClose: () => {
      setShowDialog(false);
      cleanUpPopup();
    },
    onOk: () => {
      if (newPassword === repeatedPassword) {
        signInAndUpdateUserPassword();
      } else {
        setNewPasswordErrorText(ProfileStrings.PROFILE_NEW_PASSWORD_ERROR);
      }
    },
  };

  const onChangeCurrentPassword = (value) => {
    setCurrentPasswordErrorText("");
    setCurrentPassword(value);
  };
  const onChangeNewPassword = (value) => {
    setNewPasswordErrorText("");
    setNewPassword(value);
  };
  const onChangeRepeatedPassword = (value) => {
    setNewPasswordErrorText("");
    setRepeatedPassword(value);
  };

  const cleanUpPopup = () => {
    setCurrentPassword("");
    setNewPassword("");
    setRepeatedPassword("");
    setCurrentPasswordErrorText("");
    setNewPasswordErrorText("");
  };

  const signInAndUpdateUserPassword = () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(userEmail, currentPassword)
      .then((user) => {
        updateUserPassword();
      })
      .catch((err) => {
        console.debug(ProfileStrings.PROFILE_CURRENT_PASSWORD_ERROR, err);
        setCurrentPasswordErrorText(
          ProfileStrings.PROFILE_CURRENT_PASSWORD_ERROR
        );
      });
  };

  const updateUserPassword = () => {
    firebase
      .auth()
      .currentUser.updatePassword(newPassword)
      .then(() => {
        console.debug(ProfileStrings.PROFILE_UPDATE_PASSWORD_SUCCESS_MESSAGE);
        enqueueSnackbar(ProfileStrings.PROFILE_UPDATE_PASSWORD_SUCCESS_MESSAGE, { variant: "success" });
        if (mounted.current) setShowDialog(false);
      })
      .catch((err) => {
        console.debug(ProfileStrings.PROFILE_UPDATE_PASSWORD_ERROR, err);
        enqueueSnackbar(ProfileStrings.PROFILE_UPDATE_PASSWORD_ERROR, {
          variant: "error",
        });
      });
  };

  return (
    <>
      <MyCard config={configCard} canRead={true} canEdit={true}>
        <div className={classes.row}>
          <div className={classes.header}>
            {ProfileStrings.PROFILE_SECURITY_EMAIL_ADDRESS_TITLE}
          </div>
          <div>{userEmail}</div>
        </div>
        <div className={classes.row}>
          <div className={classes.header}>
            {ProfileStrings.PROFILE_SECURITY_PASSWORD_TITLE}
          </div>
          <div>xxxxxxxx</div>
        </div>
      </MyCard>
      <MyDialog open={showDialog} config={configDialog}>
        <Box className={classes.dialogContent}>
          <Box m={1}>
            <TextField
              name="email"
              label={ProfileStrings.PROFILE_SECURITY_EMAIL_ADDRESS}
              value={userEmail}
              type="email"
              className={classes.dialogEmail}
            />
          </Box>
          <Box m={1}>
            <PasswordField
              name="password"
              label={ProfileStrings.PROFILE_SECURITY_CURRENT_PASSWORD}
              width="100%"
              value={currentPassword}
              onChange={onChangeCurrentPassword}
              error={currentPasswordErrorText}
            />
          </Box>
          <Box m={1}>
            <PasswordField
              name="password"
              label={ProfileStrings.PROFILE_SECURITY_NEW_PASSWORD}
              width="100%"
              value={newPassword}
              onChange={onChangeNewPassword}
            />
          </Box>
          <Box m={1}>
            <PasswordField
              name="password"
              label={ProfileStrings.PROFILE_SECURITY_REPEAT_NEW_PASSWORD}
              width="100%"
              value={repeatedPassword}
              onChange={onChangeRepeatedPassword}
              error={newPasswordErrorText}
            />
          </Box>
        </Box>
      </MyDialog>
    </>
  );
};

export default UserAccountCard;
