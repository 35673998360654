import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useFirestore,
  useFirestoreConnect,
  isLoaded,
} from "react-redux-firebase";

import { COLLECTIONS } from "../utils/firestoreUtils";

// get user info => dict
export const useUserInfo = (userId) => {
  const collection = COLLECTIONS.USER;
  const config = userId && {
    collection,
    doc: userId,
  };
  useFirestoreConnect(config);
  return useSelector(({ firestore: { data } }) => {
    const infos = data[collection];
    return isLoaded(infos) && userId in infos && infos[userId];
  });
};

export const useUserName = (userId) => {
  const info = useUserInfo(userId);
  return info && info.name;
};

// get user picture => string
export const useUserPicture = (userId) => {
  const info = useUserInfo(userId);
  return info && info.img;
};

export const useProjectUsersCount = (projectId) =>
  useProjectUserIds(projectId).length;

// list of userId => array
export const useProjectUserIds = (projectId) => {
  const firestore = useFirestore();
  const [userIds, setUserIds] = useState([]);

  useEffect(() => {
    if (projectId) {
      return firestore
        .collection(COLLECTIONS.USER_PROJECT)
        .where("projectId", "==", projectId)
        .onSnapshot(
          (res) => {
            setUserIds(res.docs.map((doc) => doc.data().usersId));
          },
          (error) => {}
        );
    }
  }, [firestore, projectId]);

  return userIds;
};

export const useUserId = () => {
  const profile = useSelector(
    ({ firebase: { profile } }) => isLoaded(profile) && profile
  );
  if (profile) return profile.usersId;
  else return;
};
