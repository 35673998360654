import React, {useRef, useState} from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import {useMobileLayout} from "../../hooks/uiHooks";
import {useProjectInfo} from "../../hooks/projectHooks";
import {updateProject} from "../../services/ProjectService";
import {PATTERN_NO_PUNCTUATION} from "../../utils/generalUtils";
import {createProject} from "../../services/ProjectService";

import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import AddPhotoAlternateOutlinedIcon from "@material-ui/icons/AddPhotoAlternateOutlined";

import {ProjectStrings} from "../../strings";

const DEFAULT_DESCRIPTION_ROWS = 4;

/*
 sample config
 */
// const config = {
//   onClose: () => {},
//   onConfirm: () => {}
//   onComplete: () => {}
// }

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: theme.palette.background.default,
    minHeight: 240,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  content: {
    display: "flex",
    flexDirection: "column",
  },
}));

// projectId = edit existing
// userId = new project
const ProjectDialog = ({
  projectId,
  userId,
  open,
  config: {onClose, onConfirm, onComplete},
}) => {
  const mobile = useMobileLayout();
  const classes = useStyles();
  const projectInfo = useProjectInfo(projectId);

  const [editImageURL, setEditImageURL] = useState(null);
  const [editName, setEditName] = useState(null);
  const [editBrand, setEditBrand] = useState(null);
  const [editDesc, setEditDesc] = useState(null);

  const [inputImage, setInputImage] = useState(null);
  const fileInput = useRef(null);

  const onChangeImage = (file) => {
    const frUrl = new FileReader();
    frUrl.onload = (r) => setInputImage(r.target.result);
    frUrl.readAsDataURL(file);
    setEditImageURL(file);
  };

  const getField = (edit, field) =>
    edit !== null ? edit : (projectInfo && projectInfo[field]) || "";

  const imageURL = getField(editImageURL, "imageURL");
  const name = getField(editName, "name");
  const brand = getField(editBrand, "brand");
  const desc = getField(editDesc, "desc");

  const shouldDisable = () => {
    return (
      // name mandatory
      name.length === 0 ||
      // brand mandatory
      brand.length === 0 ||
      // no change
      (projectInfo &&
        projectInfo.name === name &&
        projectInfo.brand === brand &&
        projectInfo.desc === desc &&
        projectInfo.imageURL === imageURL)
    );
  };

  const resetData = () => {
    setInputImage(null);
    setEditImageURL(null);
    setEditName(null);
    setEditBrand(null);
    setEditDesc(null);
  };

  const createProjectInternal = (info) =>
    createProject({
      name: info.name,
      brand: info.brand,
      desc: info.desc,
      url: info.imageURL,
    });

  const onConfirmInternal = () => {
    if (projectId) {
      // edit existing project
      const info = {
        name,
        brand,
        desc,
      };
      // update image only if changed
      if (inputImage) {
        info.imageURL = imageURL;
      }
      updateProject({projectId, info})
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          if (onComplete) onComplete();
        });
    } else if (userId) {
      // create new project
      const info = {
        name,
        brand,
        desc,
      };
      // set image only if exist
      if (inputImage) {
        info.imageURL = imageURL;
      }
      createProjectInternal(info)
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          if (onComplete) onComplete();
        });
    }

    if (onConfirm) {
      onConfirm();
    }

    // since the dialog will stay in DOM, it is necessary to clear the data
    resetData();
  };

  const onCloseInternal = () => {
    if (onClose) {
      onClose();
    }
    // since the dialog will stay in DOM, it is necessary to clear the data
    resetData();
  };

  const onUpdateName = (e) => {
    const input = e.target;
    if (input.validity.valid) setEditName(input.value);

    const start = input.validity.valid
      ? input.selectionStart
      : input.selectionStart - 1;
    window.requestAnimationFrame(() => {
      input.selectionStart = start;
      input.selectionEnd = start;
    });
  };

  return (
    <Dialog
      fullWidth
      fullScreen={mobile}
      maxWidth="xs"
      open={open}
      onClose={onCloseInternal}
    >
      <DialogTitle
        className={classes.title}
        disableTypography
        onClick={(e) => fileInput.current.click()}
        style={{
          backgroundImage: `url(${inputImage ? inputImage : imageURL})`,
        }}
      >
        <AddPhotoAlternateOutlinedIcon fontSize="large" hidden />
        <input
          type="file"
          ref={fileInput}
          hidden
          accept="image/gif, image/jpeg, image/png"
          onChange={(e) => onChangeImage(e.target.files[0])}
        />
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Box m={1}>
          <TextField
            label={ProjectStrings.PROJECT_DIALOG_NAME}
            value={name}
            fullWidth
            onChange={onUpdateName}
            inputProps={{
              pattern: PATTERN_NO_PUNCTUATION,
            }}
          />
        </Box>
        <Box m={1}>
          <TextField
            label={ProjectStrings.PROJECT_DIALOG_BRAND}
            value={brand}
            fullWidth
            onChange={(e) => setEditBrand(e.target.value)}
          />
        </Box>
        <Box flexGrow={1} m={1}>
          <TextField
            label={ProjectStrings.PROJECT_DIALOG_DESCRIPTION}
            value={desc}
            fullWidth
            multiline
            minRows={DEFAULT_DESCRIPTION_ROWS}
            onChange={(e) => setEditDesc(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <IconButton onClick={onCloseInternal}>
          <CloseIcon />
        </IconButton>
        <IconButton disabled={shouldDisable()} onClick={onConfirmInternal}>
          <CheckIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectDialog;
