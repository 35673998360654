import React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  IconButton,
} from "@material-ui/core";

import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

const ConfirmDialog = ({ open, title, description, onConfirm, onClose }) => {
  return (
    <Dialog open={open}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <IconButton onClick={onConfirm}>
          <CheckIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
