import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { useProjectAllUsers } from "../hooks/projectHooks";
import { useMobileLayout } from "../hooks/uiHooks";
import { TEAM_SORT_OPTION_FIELD, TEAM_SORT_OPTION } from "../utils/teamUtils";

import Spinner from "./Spinner";
import TeamMemberCard from "./cards/TeamMemberCard";

const DISPLAY_ITEMS = {
  DEFAULT: 24,
  // MAX: 120,
  LOAD_MORE: 12,
  MOBILE_DIVIDER: 6,
};

const useStyles = makeStyles((theme) => ({
  root: (mobile) => ({
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "center",
  }),
  cardContainer: (mobile) => ({
    padding: theme.spacing(1),
    width: mobile ? (window.innerWidth / 2) * 0.9 : 190,
    height: 300,
  }),
}));

const ProjectTeamList = ({
  config: { filter, sortOption, sortDesc, projectId, onClick, progressId },
  canReadPermission,
}) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);

  const sort =
    sortOption && sortOption in TEAM_SORT_OPTION_FIELD
      ? TEAM_SORT_OPTION_FIELD[sortOption]
      : TEAM_SORT_OPTION_FIELD[TEAM_SORT_OPTION[0]];

  const params = {
    limit: DISPLAY_ITEMS.MAX,
    startAt: 0,
    orderBy: sort,
    orderDesc: sortDesc,
    filter: filter,
  };

  const users = useProjectAllUsers(projectId, params);
  if (!users) return <Spinner />;

  const configCard = {
    onClick,
  };

  return (
    <div className={classes.root}>
      {users &&
        Object.entries(users).map(([key, user]) => (
          <div key={key} className={classes.cardContainer}>
            <TeamMemberCard
              userId={user.usersId}
              projectId={projectId}
              config={configCard}
              canReadPermission={canReadPermission}
              progress={progressId === user.usersId}
            />
          </div>
        ))}
    </div>
  );
};

export default ProjectTeamList;
