import React from "react";
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Avatar,
  LinearProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import AssignmentIndOutlinedIcon from "@material-ui/icons/AssignmentIndOutlined";
import SupervisorAccountOutlinedIcon from "@material-ui/icons/SupervisorAccountOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import PhotoLibraryOutlinedIcon from "@material-ui/icons/PhotoLibraryOutlined";

import { useUserName, useUserPicture } from "../../hooks/userHooks";
import { useUserProjectPermissions } from "../../services/ProjectService";
import { getNameInitials } from "../../utils/generalUtils";

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  action: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    "&:last-child": {
      paddingBottom: theme.spacing(2),
    },
  },
  media: {
    flexGrow: 8,
    height: 1,
  },
  content: {
    flexGrow: 3,
    height: 1,
  },
  emptyMedia: {
    width: "100%",
    fontSize: 80,
    fontWeight: 900,
  },
  name: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontWeight: 600,
  },
  permission: {
    fontSize: 12,
    color: "#747474",
  },
}));

const TeamMemberCard = ({
  userId,
  projectId,
  config,
  canReadPermission,
  progress,
}) => {
  const classes = useStyles();
  const userName = useUserName(userId);
  const userImage = useUserPicture(userId);
  const userPermission = useUserProjectPermissions({ userId, projectId });
  const permissionName = userPermission && userPermission.name;
  const userNameLetters = getNameInitials(userName);
  const onClick = config && config.onClick;

  const firstName = userName && userName.length > 0 && userName.split(" ")[0];

  const prepareIcon = () => {
    switch (permissionName) {
      case "Creator":
        return <PermIdentityIcon />;
      case "Admin":
        return <AssignmentIndOutlinedIcon />;
      case "Manager":
        return <SupervisorAccountOutlinedIcon />;
      case "Viewer":
        return <VisibilityOutlinedIcon />;
      case "Content Only":
      default:
        return <PhotoLibraryOutlinedIcon />;
    }
  };

  const gotoProfile = () => {
    if (onClick)
      onClick(
        userId,
        userImage,
        userNameLetters,
        userName,
        permissionName,
        prepareIcon()
      );
  };

  return (
    <Card className={classes.card}>
      {progress && <LinearProgress />}
      <CardActionArea
        className={classes.action}
        onClick={!progress ? gotoProfile : null}
      >
        {userImage ? (
          <CardMedia className={classes.media} image={userImage} />
        ) : (
          <Avatar
            className={clsx(classes.media, classes.emptyMedia)}
            variant="square"
          >
            {userNameLetters}
          </Avatar>
        )}
        <CardContent className={classes.content}>
          {canReadPermission && prepareIcon()}
          {firstName && <div className={classes.name}>{firstName}</div>}
          {permissionName && canReadPermission && (
            <div className={classes.permission}>{permissionName}</div>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default TeamMemberCard;
