const STRINGS = {
  PROFILE_SECURITY_CARD_TITLE: "Security",
  PROFILE_SECURITY_EMAIL_ADDRESS_TITLE: "Email Address:",
  PROFILE_SECURITY_PASSWORD_TITLE: "Password:",
  PROFILE_SECURITY_FULL_NAME: "Full Name",
  PROFILE_SECURITY_JOB_TITLE: "Job Title",
  PROFILE_SECURITY_COMPANY: "Company",
  PROFILE_SECURITY_EMAIL_ADDRESS: "Email Address",
  PROFILE_SECURITY_CURRENT_PASSWORD: "Current Password",
  PROFILE_SECURITY_NEW_PASSWORD: "New Password",
  PROFILE_SECURITY_REPEAT_NEW_PASSWORD: "Repeat New Password",
  PROFILE_CURRENT_PASSWORD_ERROR: "Current password is incorrect!",
  PROFILE_NEW_PASSWORD_ERROR: "Passwords do not match!",
  PROFILE_UPDATE_PASSWORD_ERROR: "Error occured while updating password!",
  PROFILE_UPDATE_PASSWORD_SUCCESS_MESSAGE: "Profile / Security Updated!",
  PROFILE_UPDATE_PROFILE_SUCCESS_MESSAGE: "Profile Updated!",
  PROFILE_REMOVE_TEAM_MEMBER: "Remove User",

  PROFILE_NOTI_TITLE: "Notifications",
  PROFILE_NOTI_MESSAGE: "Customise your notification settings.",

  // align with SystemEventService.EVENT_CATEGORIES
  PROFILE_NOTI_CATEGORIES: {
    PROJECT: "Project",
    MEDIA: "Media",
    DEVICE: "Device",
    ANALYTICS: "Analytics",
    TEAM: "Team",
    USER: "User",
  },

  SORT_OPTION_MOSTRECENT: "Most Recent",
  SORT_OPTION_NAME: "Name",
  SORT_OPTION_ROLE: "Role",

  PROFILE_EDIT_DRAG_TO_RESPOSITION: "Drag to reposition",
};

export default STRINGS;
