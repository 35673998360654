import React from "react";

import NoteCard from "./NoteCard";

import { useProjectNote } from "../../services/ProjectService";
import { updateProjectNote } from "../../services/ApiService";

const ProjectNoteCard = ({ projectId, canRead, canEdit }) => {
  const projectNote = useProjectNote(projectId);

  const onSave = (note) => updateProjectNote({ projectId, note });

  return (
    <NoteCard
      defaultNote={projectNote}
      onSave={onSave}
      canRead={canRead}
      canEdit={canEdit}
    />
  );
};

export default ProjectNoteCard;
