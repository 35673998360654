import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Badge, Tab, Tabs } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ViewCarouselOutlinedIcon from "@material-ui/icons/ViewCarouselOutlined";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import clsx from "clsx";

import { useUserHasNewEvent } from "../services/SystemEventService";

import { ROUTES } from "../route";
import { NavigationStrings } from "../strings";
import { backgroundColor } from "../configs/theme";

const useStyles = makeStyles((theme) => ({
  tab: {
    height: 80,
    width: 80,
    minWidth: 80,
    textTransform: "capitalize",
  },
  mobileTabs: {
    "box-shadow": "0px 0px 10px rgba(0, 0, 0, 0.35)",
    backgroundColor: theme.palette.background.default,
  },
}));

const UnreadIcon = ({ icon }) => {
  const unread = useUserHasNewEvent();
  return (
    <Badge color="primary" badgeContent="" invisible={!unread}>
      {icon}
    </Badge>
  );
};

const NavigationBar = (props) => {
  const tabsConfig = [
    {
      icon: <ViewCarouselOutlinedIcon />,
      label: NavigationStrings.PROJECTS,
      route: ROUTES.PROJECTS,
    },
    {
      icon: <PermIdentityOutlinedIcon />,
      label: NavigationStrings.PROFILE,
      route: ROUTES.PROFILE,
    },
    {
      icon: <UnreadIcon icon={<NotificationsNoneOutlinedIcon />} />,
      // icon: <NotificationsNoneOutlinedIcon />,
      label: NavigationStrings.NEWS,
      route: ROUTES.NEWS,
    },
  ];
  const classes = useStyles();
  const history = useHistory();
  const [currentTab] = useState(
    tabsConfig.findIndex((tab) => tab.route === history.location.pathname)
  );

  const { mobile } = props;
  const onTabChange = (event, newTab) => {
    if (currentTab !== newTab) {
      // setCurrentTab(newTab);
      history.push(tabsConfig[newTab].route);
    }
  };
  return (
    <Tabs
      // className={mobile ? classes.mobileTabs : null}
      className={clsx(mobile && classes.mobileTabs)}
      orientation={mobile ? "horizontal" : "vertical"}
      name="tab"
      value={currentTab >= 0 ? currentTab : false}
      onChange={onTabChange}
      variant={mobile ? "fullWidth" : "standard"}
      indicatorColor="primary"
      textColor="primary"
      TabIndicatorProps={{ style: { backgroundColor } }}
    >
      {tabsConfig.map((tab, index) => (
        <Tab
          className={classes.tab}
          key={tab.label}
          icon={tab.icon}
          label={currentTab === index ? tab.label : ""}
        />
      ))}
    </Tabs>
  );
};

export default NavigationBar;
