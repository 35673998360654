import { useCollection, useDocument, getDocument } from "./FirestoreService";
import { filterDeleted } from "utils/generalUtils";

const COLLECTIONS = {
  DEFAULT_TASKS: "default_tasks",
  APPLICATIONS: "applications",
  COMMANDS: "commands",

  APPLICATION_VARIANTS: "application_variants",
  APPLICATION_VERSIONS: "application_versions",

  COMMAND_VARIANTS: "command_variants",
};

export const useDefaultTasks = (stage = null) =>
  filterDeleted(
    useCollection({
      collection: COLLECTIONS.DEFAULT_TASKS,
      // ...(stage && {
      // where: [[KEYS.STAGE, "array-contains", stage]]
      // })
    })
  );

// put here for now
export const useDefaultCommands = () =>
  filterDeleted(
    useCollection({
      collection: COLLECTIONS.COMMANDS,
      // ...(stage && {
      // where: [[KEYS.STAGE, "array-contains", stage]]
      // })
    })
  );

export const useDefaultCommand = (id) =>
  useDocument({
    collection: COLLECTIONS.COMMANDS,
    doc: id,
  });

export const useDefaultCommandVariants = (id) =>
  filterDeleted(
    useCollection({
      collection: `${COLLECTIONS.COMMANDS}/${id}/${COLLECTIONS.COMMAND_VARIANTS}`,
    })
  );

// put here for now
export const useDefaultApps = () =>
  filterDeleted(
    useCollection({
      collection: COLLECTIONS.APPLICATIONS,
      // ...(stage && {
      // where: [[KEYS.STAGE, "array-contains", stage]]
      // })
    })
  );

export const useDefaultApp = (id) =>
  useDocument({
    collection: COLLECTIONS.APPLICATIONS,
    doc: id,
  });

export const getAppVariant = (appId, variantId) =>
  getDocument({
    collection: `${COLLECTIONS.APPLICATIONS}/${appId}/${COLLECTIONS.APPLICATION_VARIANTS}`,
    doc: variantId,
  });

export const useDefaultAppVariant = (appId, variantId) =>
  useDocument({
    collection: `${COLLECTIONS.APPLICATIONS}/${appId}/${COLLECTIONS.APPLICATION_VARIANTS}`,
    doc: variantId,
  });

export const useDefaultAppVariantVersion = (appId, variantId, verId) =>
  useDocument({
    collection: `${COLLECTIONS.APPLICATIONS}/${appId}/${COLLECTIONS.APPLICATION_VARIANTS}/${variantId}/${COLLECTIONS.APPLICATION_VERSIONS}`,
    doc: verId,
  });

export const useDefaultAppVariantAllVersions = (appId, variantId) =>
  useCollection({
    collection: `${COLLECTIONS.APPLICATIONS}/${appId}/${COLLECTIONS.APPLICATION_VARIANTS}/${variantId}/${COLLECTIONS.APPLICATION_VERSIONS}`,
  });

export const useAppVariantDefaultVersion = (id, variantId) => {
  const res = filterDeleted(useDefaultAppVariantAllVersions(id, variantId));
  const def = res && Object.entries(res).filter(([k, v]) => v.defaultVersion);
  return def.length > 0 ? { ...def[0][1], id: def[0][0] } : null;
};
