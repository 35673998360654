import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { useProjectPins } from "services/ProjectService";

import MyCard from "./MyCard";
import Filter4OutlinedIcon from "@material-ui/icons/Filter4Outlined";

import ProjectStrings from "../../strings/project";

const config = {
  icon: <Filter4OutlinedIcon />,
  info: ProjectStrings.OVERVIEW_PIN_CARD_INFO,
  title: ProjectStrings.OVERVIEW_PIN_CARD_TITLE,
};

const PIN_NAMES = {
  pinNumber: ProjectStrings.OVERVIEW_PIN_CARD_ADMIN,
  securityPin: ProjectStrings.OVERVIEW_PIN_CARD_SECURITY,
};

const useStyles = makeStyles((theme) => ({
  pinsContiner: {
    display: "flex",
  },
  pinName: {
    flex: 1,
  },
  pinNumber: {
    fontFamily: "myplayer-monospace",
    fontWeight: "bold",
  },
}));

const PinCard = ({ projectId, canRead }) => {
  const classes = useStyles();
  const pins = useProjectPins(projectId);
  const content = !pins ? (
    <></>
  ) : Object.keys(pins).length === 1 ? (
    // only one pin
    Object.values(pins)[0]
  ) : (
    // multiple pins
    Object.entries(pins).map(([k, v]) => (
      <div key={k} className={classes.pinsContiner}>
        <span className={classes.pinName}>{PIN_NAMES[k] ?? k}:</span>
        <span className={classes.pinNumber}>{v}</span>
      </div>
    ))
  );
  return (
    <MyCard config={config} canRead={canRead}>
      {content}
    </MyCard>
  );
};

export default PinCard;
