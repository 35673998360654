import React, { useRef, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import MoreIcon from "@material-ui/icons/MoreVert";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import { makeStyles } from "@material-ui/core/styles";

import { useMobileLayout } from "../../hooks/uiHooks";
import { DefaultStrings } from "../../strings";

const useStyles = makeStyles((theme) => ({
  title: (mobile) => ({
    backgroundColor: theme.palette.background.default,
    width: mobile ? "100vw" : 240,
    height: mobile ? "100vw" : 240,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  }),
  content: {
    display: "flex",
    flexDirection: "column",
  },
  leftButton: {
    display: "flex",
    justifyContent: "left",
    marginRight: "auto",
  },
}));

const ProfileDialog = ({
  userImage,
  titleChildren,
  children,
  open,
  config: { showButtonBar, onUpload, overflow, disableOk, onClose, onOk },
}) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);
  const [anchorOverflow, setAnchorOverflow] = useState(null);
  const fileInput = useRef(null);

  const onChangeImage = (file) => {
    if (onUpload) {
      onUpload(file);
    }
  };

  const onOverflowAction = (func) => {
    setAnchorOverflow(null);
    if (func) func();
  };

  const onConfirm = () => {
    if (onOk) {
      onOk({});
    }
  };

  const onClosePopup = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Dialog
      fullScreen={mobile}
      maxWidth="xs"
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      {userImage ? (
        <DialogTitle
          className={classes.title}
          disableTypography
          style={{ backgroundImage: `url(${userImage})` }}
        ></DialogTitle>
      ) : (
        titleChildren
      )}

      <DialogContent>{children}</DialogContent>
      {showButtonBar && (
        <DialogActions>
          {/* Image Button */}
          {onUpload && (
            <IconButton
              className={classes.leftButton}
              aria-label={DefaultStrings.BUTTON_IMAGE}
              onClick={(e) => fileInput.current.click()}
            >
              <ImageOutlinedIcon />
              <input
                type="file"
                ref={fileInput}
                hidden
                accept="image/gif, image/jpeg, image/png"
                onChange={(e) => onChangeImage(e.target.files[0])}
              />
            </IconButton>
          )}

          {/* Overflow */}
          {overflow && (
            <>
              <IconButton
                className={classes.leftButton}
                aria-label={DefaultStrings.BUTTON_OVERFLOW}
                edge="end"
                color="inherit"
                onClick={(e) => setAnchorOverflow(e.currentTarget)}
              >
                <MoreIcon />
              </IconButton>
              <Menu
                id="menuOverflow"
                anchorEl={anchorOverflow}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={anchorOverflow !== null}
                onClose={(e) => setAnchorOverflow(null)}
              >
                {overflow.map((action) => (
                  <MenuItem
                    key={action.label}
                    onClick={(e) => onOverflowAction(action.onClick)}
                    disabled={action.disabled}
                  >
                    {action.label}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
          <IconButton
            aria-label={DefaultStrings.BUTTON_CANCEL}
            onClick={onClosePopup}
          >
            <CloseIcon />
          </IconButton>
          <IconButton
            aria-label={DefaultStrings.BUTTON_CONFIRM}
            onClick={onConfirm}
            disabled={disableOk}
          >
            <CheckIcon />
          </IconButton>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ProfileDialog;
