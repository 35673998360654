export const PROJECT_SORT_OPTION_FIELD = {
  SORT_OPTION_MOSTRECENT: "modified_date",
  SORT_OPTION_NAME: "name",
  SORT_OPTION_BRAND: "brand",
  SORT_OPTION_DATE: "creation_date",
  SORT_OPTION_STATUS: "status",
};

export const PROJECT_SORT_OPTION = Object.keys(PROJECT_SORT_OPTION_FIELD);

export const KEYBOARD_KEYS = {
  ENTER: 13,
  ARROW_UP: 38,
  ARROW_DOWN: 40,
  SPACE: 32,
};

export const projectContainsFilter = ({ info, filter }) => {
  // nothing to check
  if (!info || !filter) return true;

  if (filter.length === 0) return true;

  const filterLower = filter.toLowerCase();
  const nameLower = info.name && info.name.toLowerCase();
  const brandLower = info.brand && info.brand.toLowerCase();
  const descLower = info.desc && info.desc.toLowerCase();
  return (
    (nameLower && nameLower.includes(filterLower)) ||
    (brandLower && brandLower.includes(filterLower)) ||
    (descLower && descLower.includes(filterLower))
  );
};

export const isProjectDeleted = (info) => info && info.deleted;

export const getProjectCreatorId = (info) => info && info.creatorId;
