import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, TextField } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";

import {
  updateMediaDraftName,
  deleteMediaDraft,
  publishMediaDraft,
} from "../../services/ApiService";
import { isLoading } from "../../utils/uiUtils";
import { useConfirm } from "../../hooks/uiHooks";
import { useMyState, setMyState } from "../../services/StateService";
import { useUserProjectPermissions } from "../../services/ProjectService";
import { useCurrentUserId } from "../../services/UserService";
import * as Permissions from "../../utils/permissionUtils";

import EditIcon from "@material-ui/icons/Edit";

import ButtonCard from "./ButtonCard";
import DraftCard from "./DraftCard";
import MyDialog from "../MyDialog";
import UploadProjectDraft from "../UploadProjectDraft";
import ProgressDialog from "../dialogs/ProgressDialog";

import { DefaultStrings, ProjectStrings } from "../../strings";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  fill: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  card: {
    padding: theme.spacing(1),
    "&:last-child": {
      paddingBottom: 0,
    },
  },
}));

// TODO: move to external service
const getEditLink = (id) =>
  `${
    window.location.hostname === "localhost"
      ? "http://localhost:3000"
      : process.env.REACT_APP_DEMO_MODE
      ? "https://create-test.myplayer.io"
      : "https://create-test.myplayer.io"
  }/mystories/${id}`;

const DraftListCard = ({ projectId, drafts }) => {
  const classes = useStyles();
  const [showUpload, setShowUpload] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showPublishDialog, setShowPublishDialog] = useState(false);
  const [draftName, setDraftName] = useState();
  const [changeName, setChangeName] = useState();
  const [firstUpload, setFirstUpload] = useState(false);
  const [progressDraft, setProgressDraft] = useState();
  const version = useMyState({ path: `${projectId}/media/version` });
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  // permissions control
  const userId = useCurrentUserId();
  const permissions = useUserProjectPermissions({ userId, projectId });
  const canWriteContent = Permissions.canWriteContent(permissions);
  const canWriteProjectApprovals =
    Permissions.canWriteProjectApprovals(permissions);

  const loading = isLoading(drafts);

  const loadingContent = (
    <div className={classes.fill}>
      <CircularProgress />
    </div>
  );

  const configCard = {
    icon: <EditIcon />,
    title: ProjectStrings.MEDIA_DRAFT_TITLE.replace(
      "{count}",
      drafts?.length ?? 0
    ),
    buttonLabel: ProjectStrings.MEDIA_DRAFT_NEW_DRAFT,
    onClick: () => {
      setShowUpload(true);
      setFirstUpload(true);
    },
    fullHeight: true,
    disableButton: !canWriteContent,
  };

  const clearChangeName = () => {
    setShowDialog(false);
    setChangeName();
    setDraftName();
  };

  const configDialog = {
    title: ProjectStrings.MEDIA_DRAFT_MENU_CHANGE_NAME,
    onClose: () => {
      clearChangeName();
    },
    onOk: () => {
      clearChangeName();
      setProgressDraft(draftName);
      updateMediaDraftName({ projectId, draftName, name: changeName })
        .then(() => {
          enqueueSnackbar(ProjectStrings.MEDIA_DRAFT_UPDATE_NAME_OK, {
            variant: "success",
          });
        })
        .catch((err) => {
          console.warn(err);
          enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
        })
        .finally(() => {
          setProgressDraft();
        });
    },
    disableOk: !changeName,
  };

  const onUpload = (name) => {
    console.debug("onUpload", name);
    setDraftName(name);
    setShowUpload(true);
    setFirstUpload(false);
  };

  const onUploadFinish = () => {
    setDraftName();
    setShowUpload(false);
    setFirstUpload(false);
  };

  const onUpdateName = (e) => {
    setChangeName(e.target.value);
  };

  const onDraftEdit = (draftId) => {
    console.debug("onDraftEdit", draftId, getEditLink(draftId));
    window.open(getEditLink(draftId));
  };

  const onDraftChangeName = (id, name) => {
    setDraftName(id);
    setChangeName(name);
    setShowDialog(true);
  };

  const onDraftDelete = (id, name) => {
    setDraftName(id);
    confirm({
      title: ProjectStrings.MEDIA_DRAFT_MENU_DELETE,
      message: ProjectStrings.MEDIA_DRAFT_CONFIRM_DELETE.replace(
        "{name}",
        name ?? id
      ),
    })
      .then(() => {
        setProgressDraft(id);
        deleteMediaDraft({
          projectId,
          draftName: id,
          message: ProjectStrings.MEDIA_DRAFT_DELETE_MSG.replace(
            "{draft}",
            name
          ),
        })
          .then(() => {
            enqueueSnackbar(ProjectStrings.MEDIA_DRAFT_DELETE_OK, {
              variant: "success",
            });
          })
          .catch((err) => {
            console.warn(err);
            enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
          })
          .finally(() => {
            setProgressDraft();
          });
      })
      .catch(() => {})
      .finally(() => {
        setDraftName();
      });
  };

  const onDraftPreview = (id, name) => {
    setMyState({
      dispatch,
      path: `${projectId}/media/version`,
      data: {
        id,
        name,
      },
    });
  };

  const onDraftPublish = (id, name) => {
    setDraftName(id);
    confirm({
      title: ProjectStrings.MEDIA_DRAFT_MENU_PUBLISH,
      message: ProjectStrings.MEDIA_DRAFT_CONFIRM_PUBLISH.replace(
        "{name}",
        name ?? id
      ),
    })
      .then(() => {
        setShowPublishDialog(true);
        publishMediaDraft({
          projectId,
          draftName: id,
          message: ProjectStrings.MEDIA_DRAFT_PUBLISH_MSG.replace(
            "{draft}",
            name
          ),
        })
          .then(() => {
            // clear current version (set to live)
            setMyState({
              dispatch,
              path: `${projectId}/media/version`,
              data: {},
            });

            enqueueSnackbar(ProjectStrings.MEDIA_DRAFT_PUBLISH_OK, {
              variant: "success",
            });
          })
          .catch((err) => {
            console.warn(err);
            enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
          })
          .finally(() => {
            if (mounted.current) {
              setShowPublishDialog(false);
              setDraftName();
            }
          });
      })
      .catch(() => {
        setDraftName();
      });
  };

  return (
    <>
      <UploadProjectDraft
        open={showUpload}
        projectId={projectId}
        draftName={draftName}
        firstUpload={firstUpload}
        onFinish={onUploadFinish}
      />
      <ButtonCard className={classes.root} config={configCard} canRead>
        {loading
          ? loadingContent
          : drafts?.map((d) => (
              <div key={`draft-${d.id}`} className={classes.card}>
                <DraftCard
                  projectId={projectId}
                  draft={d}
                  onUpload={() => onUpload(d.id)}
                  highlight={draftName === d.id}
                  progress={progressDraft === d.id}
                  onEdit={onDraftEdit}
                  onChangeName={onDraftChangeName}
                  onDelete={onDraftDelete}
                  onPreview={onDraftPreview}
                  onPublish={onDraftPublish}
                  previewing={version?.id === d.id}
                  canEdit={canWriteContent}
                  canApprove={canWriteProjectApprovals}
                />
              </div>
            ))}
        <MyDialog config={configDialog} open={showDialog}>
          <TextField
            label={ProjectStrings.MEDIA_DRAFT_NAME}
            value={changeName ?? ""}
            fullWidth
            onChange={onUpdateName}
          />
        </MyDialog>
        <ProgressDialog
          open={showPublishDialog}
          config={{
            title: ProjectStrings.MEDIA_DRAFT_PUBLISHING,
            desc: ProjectStrings.MEDIA_DRAFT_PUBLISHING_DESC,
          }}
        />
      </ButtonCard>
    </>
  );
};

export default DraftListCard;
