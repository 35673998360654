import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Spinner from "../ui/Spinner";

import { useMobileLayout } from "../hooks/uiHooks";

import { DefaultStrings } from "../strings";

const useStyles = makeStyles((theme) => {
  return {
    centered: (mobile) => ({
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      padding: mobile ? theme.spacing(4) : 0,
    }),
  };
});

// permitted = undefined => loading
// permitted = false => restricted
// permitted = true => show children
const RestrictedContent = ({
  children,
  permitted,
  icon = true,
  text = true,
}) => {
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);

  const isLoading = typeof permitted === "undefined";

  return isLoading ? (
    <div className={classes.centered}>
      <Spinner />
    </div>
  ) : !permitted ? (
    <div className={classes.centered}>
      {icon && (
        <Box m={1}>
          <LockOutlinedIcon />
        </Box>
      )}
      {text && (
        <Typography variant="caption">
          {DefaultStrings.PERMISSONS_NO_PERMISSION}
        </Typography>
      )}
    </div>
  ) : (
    children ?? <></>
  );
};

export default RestrictedContent;
