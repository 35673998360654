import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";

import { DefaultStrings } from "../strings";

/*
 sample config
 */
// const config = {
//   desktopOnly: true,
//   primary: {
//     icon: <EditIcon />,
//     onClick: () => {
//       console.debug("primaryAction");
//     },
//     disabled: false,
//   },
//   actions: [
//     {
//       icon: <ViewCarouselOutlinedIcon />,
//       name: "Projects",
//       onClick: () => {
//         console.debug("action");
//       },
//       disabled: true,
//     },
//     {
//       icon: <PermIdentityOutlinedIcon />,
//       name: "Profile",
//       onClick: () => {
//         console.debug("action");
//       },
//     },
//   ],
// };

const useStyles = makeStyles({
  desktop: {
    margin: 12,
    width: window.innerWidth,
  },
  mobile: {
    marginRight: 36,
    marginBottom: 24,
  },
});

const ActionButton = ({
  mobile,
  config: { primary, actions, desktopOnly },
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const hasPrimaryAction = primary?.onClick;
  const hasActions = actions?.length > 0;
  const hidden = !hasPrimaryAction && !hasActions;
  const iconMain = primary?.icon ?? <SpeedDialIcon />;

  const onClick = () => {
    if (hasPrimaryAction) {
      primary.onClick();
    } else if (hasActions) {
      setOpen(true);
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  const onAction = (func) => {
    setOpen(false);
    if (func) func();
  };

  if (mobile && desktopOnly) return <></>;

  return (
    <SpeedDial
      className={mobile ? classes.mobile : classes.desktop}
      ariaLabel={DefaultStrings.BUTTON_ACTIONS}
      icon={iconMain}
      hidden={hidden}
      onClick={onClick}
      onClose={onClose}
      // onOpen={onOpen}
      open={open}
      direction={mobile ? "up" : "right"}
      FabProps={{ disabled: primary?.disabled }}
    >
      {hasActions &&
        actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={(e) => onAction(action.onClick)}
            disabled={action.disabled}
          />
        ))}
    </SpeedDial>
  );
};

export default ActionButton;
