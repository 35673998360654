import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  useUserProjects,
  useUserProjectsCount,
} from "../services/ProjectService";
import { useMobileLayout } from "../hooks/uiHooks";
import {
  PROJECT_SORT_OPTION_FIELD,
  PROJECT_SORT_OPTION,
} from "../utils/projectUtils";
import { usePinnedProjects } from "../services/UserService";

import ProjectCard from "./cards/ProjectCard";
import Spinner from "./Spinner";
import InfiniteScroll from "./InfiniteScroll";

import { ProjectStrings } from "../strings";
import { isLoading } from "../utils/uiUtils";

const DISPLAY_ITEMS = {
  DEFAULT: 24,
  // MAX: 120,
  MAX: 100100100,
  LOAD_MORE: 12,
  MOBILE_DIVIDER: 6,
};

const useStyles = makeStyles((theme) => {
  const border = `solid 1px #E0E0E0`;
  return {
    cardContainer: ({ mobile, small }) => ({
      padding: theme.spacing(1),
      paddingLeft: mobile ? theme.spacing(2) : theme.spacing(1),
      paddingRight: mobile ? theme.spacing(2) : theme.spacing(1),
      width: mobile ? window.innerWidth : small ? 240 : 320,
      height: small ? 240 : 320,
      boxSizing: "border-box",
    }),
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      overflowX: "hidden",
    },
    main: {
      padding: theme.spacing(2),
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      justifyContent: "center",
    },
    more: {
      width: "80%",
      textAlign: "center",
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
      backgroundColor: "white",
      border,
    },
  };
});

// const config = {
//   filter: "filterString",
//   sortOption: "documentKey",
//   sortDesc: true,
//   userId: "userId",
//   exclude: ["projectId"],
//   small: false,
//   onClick: (projectId) => {}
// };

const ProjectList = ({
  config: {
    filter,
    sortOption,
    sortDesc,
    userId,
    exclude,
    small = false,
    onClick,
  },
}) => {
  const mobile = useMobileLayout();
  const itemMax = mobile
    ? DISPLAY_ITEMS.MAX / DISPLAY_ITEMS.MOBILE_DIVIDER
    : DISPLAY_ITEMS.MAX;
  const itemDefault = mobile
    ? DISPLAY_ITEMS.DEFAULT / DISPLAY_ITEMS.MOBILE_DIVIDER
    : DISPLAY_ITEMS.DEFAULT;
  const [limit, setLimit] = useState(itemDefault);
  const sort =
    sortOption && sortOption in PROJECT_SORT_OPTION_FIELD
      ? PROJECT_SORT_OPTION_FIELD[sortOption]
      : PROJECT_SORT_OPTION_FIELD[PROJECT_SORT_OPTION[0]];
  const params = {
    limit,
    startAt: 0,
    orderBy: sort,
    orderDesc: sortDesc,
    filter,
  };
  const projects = useUserProjects({ userId, params });
  const totalProjects = useUserProjectsCount(userId);
  const pinnedProjects = usePinnedProjects();
  const classes = useStyles({ mobile, small });
  const [hoverProjectId, setHoverProjectId] = useState();

  if (
    isLoading(projects) ||
    isLoading(totalProjects) ||
    isLoading(pinnedProjects)
  )
    return <Spinner />;

  const onLoadMore = (size) => {
    if (size >= limit) {
      const newLimit = Math.min(
        limit + DISPLAY_ITEMS.LOAD_MORE,
        totalProjects,
        itemMax
      );
      if (newLimit > limit) {
        console.debug(`Showing ${newLimit} of ${totalProjects} projects`);
        setLimit(newLimit);
      }
    }
  };

  const configCard = {
    showBrand: true,
    showParticipants: !small,
    showPin: false,
    useUnread: true,
    onClick,
    clickToOpen: !onClick, // if no onClick provided we use clickToOpen
  };

  const configScroll = {
    onLoadMore,
  };

  const more = totalProjects - projects.length;

  return (
    <div className={classes.root}>
      <InfiniteScroll
        config={configScroll}
        size={projects.length}
        max={totalProjects}
      >
        <div className={classes.main}>
          {projects
            .filter(
              (project) =>
                project.projectId &&
                (!exclude || !exclude.includes(project.projectId))
            )
            .map((project) => (
              <div
                key={project.projectId}
                className={classes.cardContainer}
                onMouseOver={() => {
                  setHoverProjectId(project.projectId);
                }}
              >
                <ProjectCard
                  projectId={project.projectId}
                  info={project}
                  config={{
                    ...configCard,
                    showPin:
                      mobile ||
                      project.projectId === hoverProjectId ||
                      pinnedProjects.includes(project.projectId),
                  }}
                />
              </div>
            ))}
        </div>
      </InfiniteScroll>
      {more > 0 && (
        <div className={classes.more}>
          {ProjectStrings.LIST_MORE_HIDDEN_PROJECTS.replace("{}", more)}
        </div>
      )}
    </div>
  );
};

export default ProjectList;
