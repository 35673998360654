import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useMobileLayout, useWindowSize } from "../hooks/uiHooks";

import ActionButton from "../ui/ActionButton";
import MyAppBar from "../ui/MyAppBar";
import NavigationBar from "../ui/NavigationBar";

const navigationBarHeight = "80px";
export const navigationBarWidth = "80px";

/**
 * sample config
 */
// const config = {
//   actionButton: {},
//   appBar: {},
//   fixedHeight: false,
// }

const useStyles = makeStyles((theme) => ({
  root: ({ size, fixedHeight }) => ({
    height: fixedHeight ? size.height : "auto",
    display: "flex",
    flexDirection: "column",
  }),
  main: ({ fixedHeight }) => {
    const res = { display: "flex" };
    if (fixedHeight) {
      res.height = 1;
      res.flexGrow = 1;
    }
    return res;
  },
  left: {
    position: "fixed",
    top: "25%",
    left: 0,
    height: "100%",
    width: navigationBarWidth,
    zIndex: 100,
  },
  bottom: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    height: navigationBarHeight,
    zIndex: 100,
  },
  mobileAction: {
    position: "fixed",
    right: 0,
    bottom: navigationBarHeight,
    zIndex: 1,
  },
  actionPlaceHolder: {
    minHeight: 60,
  },
  content: () => ({
    width: "100%",
  }),
}));

const MainPage = ({ children, config }) => {
  const actionConfig = config?.actionButton ?? {};
  const appBarConfig = config?.appBar ?? {};
  const fixedHeight = config?.fixedHeight ?? false;

  const size = useWindowSize();
  const mobile = useMobileLayout();
  const classes = useStyles({ size, fixedHeight });

  return (
    <>
      {mobile ? (
        <>
          <MyAppBar mobile={true} config={appBarConfig} />
          <Box mb={navigationBarHeight} className={classes.content}>
            {children}
          </Box>
          <div className={classes.bottom}>
            <NavigationBar mobile={true} />
          </div>
          <div className={classes.mobileAction}>
            <ActionButton mobile={true} config={actionConfig} />
          </div>
        </>
      ) : (
        <div className={classes.root}>
          <MyAppBar config={appBarConfig} />
          <div className={classes.main}>
            <div className={classes.left}>
              <div className={classes.actionPlaceHolder}>
                <ActionButton config={actionConfig} />
              </div>
              <NavigationBar />
            </div>
            <Box pl={navigationBarWidth} className={classes.content}>
              {children}
            </Box>
          </div>
        </div>
      )}
    </>
  );
};

export default MainPage;
