import { createTheme } from "@material-ui/core/styles";
export const backgroundColor = "#F5F5F5";

const themeMyPlayer = createTheme({
  palette: {
    primary: { 500: "#F78130" },
    background: {
      default: backgroundColor,
    },
    text: {
      primary: "#000000",
      secondary: "#5F5F5F",
    },
    border: "#C0C0C0",
  },
  typography: {
    fontFamily: "myplayer",
    caption: {
      fontSize: 14,
      fontWeight: "bold",
    },
    h3: {
      fontWeight: "bold",
    },
    button: {
      fontSize: 16,
      fontWeight: 500,
      textTransform: "capitalize",
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        backgroundColor: backgroundColor
      }
    },
    MuiFilledInput: {
      root: {
        backgroundColor: backgroundColor
      }
    },
    MuiInput: {
      underline: {
        "&:hover:not($disabled):not($error):before": {
          borderBottom: "none",
        },
      },
    },
    // MuiBadge: {
    //   colorPrimary: {
    //     backgroundColor: "#00FF00",
    //   },
    //   colorError: {
    //     backgroundColor: "#7F7F7F",
    //   },
    // },
    // MuiChip: {
    //   colorPrimary: {
    //     backgroundColor: "#00FF00",
    //   },
    // },
    // MuiDivider: {
    //   root: {
    //     border: `2px solid ${primaryColor}`,
    //   },
    // },
    MuiButton: {
      // containedPrimary: {
      //   color: "white",
      // },
      root: {
        textTransform: "capitalize",
      },
    },
    MuiCardActionArea: {
      root: {
        fontFamily: "myplayer",
      },
    },
  },
  props: {
    MuiButton: {
      variant: "contained",
    },
    MuiTextField: {
      variant: "filled",
    },
  },
});

export default themeMyPlayer;
