import { useCollection } from "./FirestoreService";
import { useUserProjectPermissions } from "./ProjectService";
import { getCurrentUserId, useCurrentUserId } from "./UserService";

import { COLLECTIONS } from "../utils/firestoreUtils";

export const PERMISSION_KEYS = {
  ANALYTICS: "analytics",
  CODE: "code", // project share code
  CONTENT: "content", // project media content
  DEVICE_DEPLOYMENT: "deviceDeployment", // deviceDeployment is not used
  DEVICE_EXTRA: "deviceExtra",
  DEVICES: "devices",
  PROJECT: "project",
  PROJECT_APPROVALS: "projectApprovals",
  PROJECT_EXTRA: "projectExtra",
  PROJECT_MEDIA: "projectMedia",
  USER_PERMISSIONS: "userPermissions",
  USERS: "users",
  MESSAGE: "message",
};

const PERMISSION_ACTIONS = {
  READ: "read",
  WRITE: "write",
  DELETE: "delete",
};

const KEYS = {
  NAME: "name",
  ORDER: "order",
  PERMISSION_KEY: "permissionsKey",

  CHAT_ID: "id",
  CHAT_USER_ID: "uid",
};

const getPermission = (data, key) => data?.[key];

const usePermission = (projectId) =>
  useUserProjectPermissions({ userId: getCurrentUserId(), projectId });

/**
 * System permissions
 */

// get system-wide permission definitions => dict
export const usePermissions = () =>
  useCollection({ collection: COLLECTIONS.PERMISSIONS });

export const usePermissionsBasic = (projectId) => {
  const userPermission = usePermission(projectId);
  const permissions = usePermissions();
  if (!userPermission) return userPermission;
  if (!permissions) return permissions;

  return Object.values(permissions)
    .filter((p) => p[KEYS.ORDER] >= userPermission[KEYS.ORDER])
    .map((p) => ({
      [KEYS.NAME]: p[KEYS.NAME],
      [KEYS.ORDER]: p[KEYS.ORDER],
      [KEYS.PERMISSION_KEY]: p[KEYS.PERMISSION_KEY],
    }))
    .sort((a, b) => b[KEYS.ORDER] - a[KEYS.ORDER]);
};

export const useUserHasHigherRole = ({ projectId, userId, targetUserId }) => {
  const userRole = useUserProjectPermissions({
    projectId,
    userId,
  });
  const targetRole = useUserProjectPermissions({
    projectId,
    userId: targetUserId,
  });
  return (
    userRole && targetRole && userRole[KEYS.ORDER] <= targetRole[KEYS.ORDER]
  );
};

/**
 * Project Media
 */

// allow to edit project media if:
// 1) content, or
// 2) projectMedia
//
// should cover all required permissions below
export const useCanEditProjectMedia = (projectId) => {
  const data = usePermission(projectId);
  const content = getPermission(data, PERMISSION_KEYS.CONTENT);
  const projectMedia = getPermission(data, PERMISSION_KEYS.PROJECT_MEDIA);
  return (
    (content?.read && content?.write) ||
    (projectMedia?.read && projectMedia?.write)
  );
};

// in sync with rules of /projects/projects_and_info
export const useProjectAppsPermission = (projectId) => {
  const data = usePermission(projectId);
  return getPermission(data, PERMISSION_KEYS.CONTENT);
};

// currently from storage and not protected by security rules
// using CONTENT until then
export const useProjectContentPermission = (projectId) => {
  const data = usePermission(projectId);
  return getPermission(data, PERMISSION_KEYS.CONTENT);
};

// in sync with rules of /projects_and_info/{projectId}/media/
export const useProjectResolutionPermission = (projectId) => {
  const data = usePermission(projectId);
  return getPermission(data, PERMISSION_KEYS.PROJECT_MEDIA);
};

export const useCanEditAndDeleteMediaLink = ({ projectId, linkCreatorId }) => {
  const userId = useCurrentUserId();
  return useUserHasHigherRole({
    projectId,
    userId,
    targetUserId: linkCreatorId,
  });
};

/**
 * Chatroom
 */

export const useCanDeleteMessage = ({ message, projectId, deviceId }) => {
  const userId = useCurrentUserId();
  const data = usePermission(projectId);
  if (!message || !projectId) return false;
  if (userId === message?.[KEYS.CHAT_USER_ID]) return true;
  return getPermission(data, PERMISSION_KEYS.MESSAGE)?.write;
};

/**
 * Permission check
 */

// return undefined if permissions is undefined (loading)
const canPerformAction = (permissions, key, action) =>
  !!permissions?.[key]?.[action];

export const canReadDevices = (permissions, key) =>
  canPerformAction(
    permissions[key],
    PERMISSION_KEYS.DEVICES,
    PERMISSION_ACTIONS.READ
  );

export const canReadDeviceExtra = (permissions, key) =>
  canPerformAction(
    permissions[key],
    PERMISSION_KEYS.DEVICE_EXTRA,
    PERMISSION_ACTIONS.READ
  );

export const canReadProjectExtra = (permissions, key) =>
  canPerformAction(
    permissions[key],
    PERMISSION_KEYS.PROJECT_EXTRA,
    PERMISSION_ACTIONS.READ
  );

export const canReadProject = (permissions, key) =>
  canPerformAction(
    permissions[key],
    PERMISSION_KEYS.PROJECT,
    PERMISSION_ACTIONS.READ
  );

export const canReadUsers = (permissions, key) =>
  canPerformAction(
    permissions[key],
    PERMISSION_KEYS.USERS,
    PERMISSION_ACTIONS.READ
  );

export const canReadUserPermissions = (permissions, key) =>
  canPerformAction(
    permissions[key],
    PERMISSION_KEYS.USER_PERMISSIONS,
    PERMISSION_ACTIONS.READ
  );

export const canReadCode = (permissions, key) =>
  canPerformAction(
    permissions[key],
    PERMISSION_KEYS.CODE,
    PERMISSION_ACTIONS.READ
  );

export const canReadContent = (permissions, key) =>
  canPerformAction(
    permissions[key],
    PERMISSION_KEYS.CONTENT,
    PERMISSION_ACTIONS.READ
  );

export const canReadProjectApprovals = (permissions, key) =>
  canPerformAction(
    permissions[key],
    PERMISSION_KEYS.PROJECT_APPROVALS,
    PERMISSION_ACTIONS.READ
  );

export const canReadShowroom = (permissions, key) =>
  canPerformAction(
    permissions[key],
    PERMISSION_KEYS.PROJECT_EXTRA,
    PERMISSION_ACTIONS.READ
  );

export const canReadProjectMedia = (permissions, key) =>
  canPerformAction(
    permissions[key],
    PERMISSION_KEYS.PROJECT_MEDIA,
    PERMISSION_ACTIONS.READ
  );

export const canReadAnalytics = (permissions, key) =>
  canPerformAction(
    permissions[key],
    PERMISSION_KEYS.ANALYTICS,
    PERMISSION_ACTIONS.READ
  );
