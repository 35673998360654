import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";

import {
  useProjectDraftLive,
  useProjectDraftsUnpublished,
  useProjectDraftApprovals,
} from "../../services/ProjectService";
import { useMyState, setMyState } from "../../services/StateService";
import { useCurrentUserId } from "../../services/UserService";
import { useUserProjectPermissions } from "../../services/ProjectService";
import * as Permissions from "../../utils/permissionUtils";
import { useMobileLayout } from "../../hooks/uiHooks";

import DraftCard from "../cards/DraftCard";
import DraftListCard from "../cards/DraftListCard";
import ResolutionCard from "../cards/ResolutionCard";
import MobileContent from "../MobileContent";

import { ProjectStrings } from "../../strings";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    width: "100%",
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "stretch",
  },
  stretchWidth: {
    flexGrow: 1,
    width: 1,
    padding: theme.spacing(1),
  },
  card: {
    minHeight: 160,
    paddingBottom: theme.spacing(2),
    "&:last-child": {
      paddingBottom: 0,
    },
  },
}));

const EditContentTab = ({ projectId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const version = useMyState({ path: `${projectId}/media/version` });
  const mobile = useMobileLayout();

  const liveDraft = useProjectDraftLive(projectId);
  const drafts = useProjectDraftsUnpublished(projectId);
  const approvals = useProjectDraftApprovals({ projectId });
  const userId = useCurrentUserId();
  const permissions = useUserProjectPermissions({ userId, projectId });
  const canWriteProjectApprovals =
    Permissions.canWriteProjectApprovals(permissions);

  const onPreviewLive = () => {
    setMyState({
      dispatch,
      path: `${projectId}/media/version`,
      data: {},
    });
  };

  const liveContent = (
    <div className={classes.card}>
      <DraftCard
        projectId={projectId}
        draft={liveDraft}
        liveApprovals={approvals}
        previewing={!version?.id}
        onPreview={onPreviewLive}
        live
        canApprove={canWriteProjectApprovals}
      />
    </div>
  );

  const resolutionContent = (
    <div className={classes.card}>
      <ResolutionCard projectId={projectId} />
    </div>
  );

  if (mobile)
    return (
      <MobileContent
        title={ProjectStrings.MEDIA_PROJECT_CONTENT_NA_TITLE}
        desc={ProjectStrings.MEDIA_PROJECT_CONTENT_NA_DESC}
      />
    );

  return (
    <div className={classes.container}>
      <div className={classes.stretchWidth} style={{ flexGrow: 2 }}>
        <DraftListCard projectId={projectId} drafts={drafts} />
      </div>
      <div className={classes.stretchWidth}>
        {liveContent}
        {resolutionContent}
      </div>
    </div>
  );
};

export default EditContentTab;
