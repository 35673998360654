import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  useFirestoreConnect,
  isLoaded,
} from "react-redux-firebase";

import { COLLECTIONS } from "../utils/firestoreUtils";
import { useSelectorWithCollection } from "../services/FirestoreService";
import { teamContainsFilter } from "../utils/teamUtils";

const DELIMITER = "::";

// get info of a project => dict
export const useProjectInfo = (projectId) => {
  const collection = COLLECTIONS.PROJECT_INFO;
  const config = projectId && {
    collection,
    doc: projectId,
  };
  useFirestoreConnect(config);
  return useSelector(({ firestore: { data } }) => {
    const infos = data[collection];
    return isLoaded(infos) && infos[projectId] && infos[projectId];
  });
};

// => string
export const useProjectAnalyticsUrl = (projectId) => {
  const collection = COLLECTIONS.PROJECT_ANALYTICS;
  const config = projectId && {
    collection,
    doc: projectId,
  };
  useFirestoreConnect(config);
  return useSelector(({ firestore: { data } }) => {
    const infos = data[collection];
    if (!isLoaded(infos)) return undefined;
    const projectInfo = infos[projectId];
    if (!projectInfo || !projectInfo.analytics) return null;
    return projectInfo.analytics;
  });
};

// get the selected (installed) apps from a project
// convert the array and return as dict
// for future purpose should only make use of "pkgName" and "ver"
// default => {}
export const useProjectApps = (projectId) => {
  const collection = COLLECTIONS.PROJECT;
  const config = projectId && {
    collection,
    doc: projectId,
  };
  useFirestoreConnect(config);
  return useSelector(({ firestore: { data } }) => {
    const infos = data[collection];
    if (!isLoaded(infos)) return undefined;
    const projectInfo = infos[projectId];
    // project/pkgs not found => return empty as default
    if (!projectInfo || !projectInfo.pkgs) return {};
    else
      return projectInfo.pkgs.reduce(
        (total, current) => ({
          ...total,
          ...{ [current.pkgName]: current },
        }),
        {}
      );
  });
};

// default = ""
export const useProjectName = (projectId) => {
  const info = useProjectInfo(projectId);
  return (info && info.name) || "";
};

// get all users in a project => array
export const useProjectAllUsers = (projectId, params) => {
  const storeAsProjectAllUsers = `useProjectAllUsers${DELIMITER}${projectId}`;
  const configs = {
    collection: COLLECTIONS.USER_PROJECT,
    where: [["projectId", "==", projectId]],
    storeAs: storeAsProjectAllUsers,
    populates: [
      {
        child: "usersId",
        root: COLLECTIONS.USER,
      },
    ],
  };
  useFirestoreConnect(configs);
  const userAndProjects = useSelectorWithCollection(storeAsProjectAllUsers);
  const users = useSelectorWithCollection(COLLECTIONS.USER);
  const [usersResult, setUsersResult] = useState([]);

  const orderBy = params && params.orderBy;
  const orderDesc = params && params.orderDesc;
  const startAt = params && params.startAt;
  const limit = params && params.limit;
  const filter = params && params.filter;

  useEffect(() => {
    if (
      !userAndProjects ||
      !users ||
      Object.keys(userAndProjects).length !== Object.keys(users).length
    )
      return;
    // filter null values
    // combine data
    let res = Object.entries(userAndProjects)
      .filter((item) => item[1] != null)
      .map(([k, id]) => {
        return {
          ...users[id.usersId],
          permissionsKey: id.permissionsKey,
          creation_date: id.creationDate,
          projectId: id.projectId,
        };
      });

    // sort
    if (orderBy && typeof orderDesc !== "undefined") {
      res = res.sort((infoA, infoB) => {
        const idFirstForUsers = orderDesc ? infoB.usersId : infoA.usersId;
        const idSecondForUsers = orderDesc ? infoA.usersId : infoB.usersId;
        const idFirstForUserAndProjects = orderDesc
          ? infoB.projectId + "_" + infoB.usersId
          : infoA.projectId + "_" + infoA.usersId;
        const idSecondForUserAndProjects = orderDesc
          ? infoA.projectId + "_" + infoA.usersId
          : infoB.projectId + "_" + infoB.usersId;

        const valueFirst =
          orderBy === "name"
            ? users[idFirstForUsers] && users[idFirstForUsers][orderBy]
            : userAndProjects[idFirstForUserAndProjects] &&
              userAndProjects[idFirstForUserAndProjects][orderBy];

        const valueSecond =
          orderBy === "name"
            ? users[idSecondForUsers] && users[idSecondForUsers][orderBy]
            : userAndProjects[idSecondForUserAndProjects] &&
              userAndProjects[idSecondForUserAndProjects][orderBy];

        if (typeof valueFirst === "undefined") {
          return typeof valueSecond === "undefined" ? 0 : 1;
        }
        if (typeof valueSecond === "undefined") {
          return typeof valueFirst === "undefined" ? 0 : -1;
        }
        if (typeof valueFirst === "string" && typeof valueSecond === "string")
          return valueSecond.localeCompare(valueFirst);
        else return valueSecond - valueFirst;
      });
    }

    // filter
    if (filter) {
      res = res.filter((user) => teamContainsFilter({ user, filter }));
    }

    // limit
    if (typeof startAt !== "undefined" && limit) {
      res = res.slice(startAt, limit);
    }

    setUsersResult(res);
  }, [userAndProjects, users, orderBy, orderDesc, startAt, limit, filter]);
  // TODO: deep-compare userAndProjects and users

  return usersResult;
};
